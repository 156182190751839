<i18n>
{
  "en": {
      "title": "Acceleration Time Response",
      "subTitle": "Each event corresponds to a detected vibration. The event is represented by three graphs that describe the acceleration in mg for each axis."

  },
  "it": {
      "title": "Time Response dell'accelerazione",
      "subTitle": "Ogni evento corrisponde ad una vibrazione rilevata. L’evento è rappresentato da tre grafici che descrivono l’accelerazione in mg per ogni asse."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "timeRespAccBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 360px;
}
</style>
