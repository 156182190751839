<i18n>
{
  "en": {
      "title": "Frequency Response dell’accelerazione",
      "subTitle": "Graphs relating to the FFT (Fast Fourier Transform) algorithm of the selected event, calculated for each of the three measurement axes of the sensor. This algorithm, used to calculate the Frequency Response faster, allows to highlight the spectral content of the signals coming from the sensors. It is therefore possible to view the Frequency Response of the oscillations (mg) thanks to which it is possible to obtain characteristics and information of the signal, not perceptible in the time domain."

  },
  "it": {
      "title": "Frequency Response dell’accelerazione",
      "subTitle": "Grafici relativi all’algoritmo ’FFT (Fast Fourier Transform) dell’evento selezionato, calcolato per ognuno dei tre assi di misurazione del sensore. Questo algoritmo, utilizzato per calcolare più velocemente la Frequency Response, permette di evidenziare il contenuto spettrale dei segnali provenienti dai sensori. Risulta quindi possibile visualizzare il Frequency Response delle oscillazioni (mg) grazie alla quale è possibile ricavare caratteristiche e informazioni del segnale, non percettibili nel dominio del tempo."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "freqRespAccBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 560px;
}
</style>
