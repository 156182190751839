<i18n>
{
  "en": {
      "title": "Spectrum Peak Amplitude",
      "subTitle": "The peak width of the spectrum represents the maximum value of the FFT of each component of the velocity vector. For each observation interval, the FFT of each component is calculated by windowing the time course of the velocity around the maximum peak measured in the observation interval."
  },
  "it": {
      "title": "Ampiezze del picco dello spettro",
      "subTitle": "L’ampiezza del picco dello spettro rappresenta il valore massimo della FFT di ciascuna componente del vettore velocità. Per ogni intervallo di osservazione l’FFT di ciascuna componente è calcolata finestrando l’andamento temporale della velocità nell’intorno del picco massimo misurato nell’intervallo di osservazione."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "peakAmpVibBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 560px;
}
</style>
