<template>
  <div class="over">
    <!-- TEMPERATURES CONTAINER -->
    <div id="temps" class="temp-wrapper noselect">
      <div class="infoi" v-show="!this.getTempLoaded || !this.getTemperaturesList">
        <h2 v-if="!this.getTemperaturesList && this.getTempLoaded">{{ $t("message.noDataInRange") }}...</h2>
        <h2 v-else>{{ $t("message.loading") }}...</h2>
      </div>
      <div class="main-wrap over-info">
        <div style="display: flex;">
          <h2>{{ $t("graphTitlesDetails.temperature") }}</h2>
          <div>
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
            <TemperatureBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
          </div>
        </div>
        <p class="first-block">{{ $t("graphSubTitlesDetails.temperature") }}</p>
        <img @click="downloadTemperatures" class="download-btn" src="../../../../public/assets/download.svg" />
      </div>
      <div class="disp-trend-wrapper">
        <LinePlot :height="this.plotHeight" :traces="this.getTraces" xTitle="Time (Hr)" yTitle="Temperature (°C)" v-if="this.getSelectedSensor" />
      </div>
    </div>
  </div>
</template>
<script>
import LinePlot from "../../graphs/LinePlot.vue";
import { exportCSVFile } from "../../../helpers/download.js";
import { useTimestampFieldBySensor } from "../../../helpers/sensors";
import { mapGetters } from "vuex";
import TemperatureBalloon from "../../tutorial/balloons/details/TemperatureBl.vue";
var mql = window.matchMedia("screen and (max-width: 1499px)");

export default {
  name: "temperatures-module",
  components: {
    LinePlot,
    TemperatureBalloon,
  },
  data() {
    return {
      isInfoVisible: false,
      plotHeight: 400,
    };
  },
  methods: {
    responsiveScreenHandler: function(e) {
      // Modifico l'attributo 'height' del grafico in base alla dimensione della finestra, vedere 'mql'
      if (e.matches) {
        this.plotHeight = 350;
      } else {
        this.plotHeight = 400;
      }
    },
    /* Scarico .CSV */
    downloadTemperatures() {
      if (this.getTempLoaded) {
        let events = this.getTemperaturesList.map((item) => {
          return {
            eui: item.eui.substr(item.eui.length - 5),
            date: new Date((useTimestampFieldBySensor(this.getSelectedSensor) && item.timestamp) ? item.timestamp : item.date).toLocaleString().replace(/,/g, ""),
            temp: item.temperature.toFixed(2),
          };
        });
        let headers = {
          eui: "Device",
          date: "Data (Locale)",
          temp: "Temperature (°C)",
        };
        let title = "Temperature " + this.getSelectedSensor.eui.substr(this.getSelectedSensor.eui.length - 5);
        exportCSVFile(headers, events, title);
      }
    },
  },
  computed: {
    ...mapGetters({
      getSelectedSensor: "eventdetails/getSelectedSensor",
      getTempLoaded: "eventdetails/getTempLoaded",
      getTemperaturesList: "eventdetails/getTemperaturesList",
    }),
    getTraces: function() {
      return [
        {
          uid: "34534sa",
          line: {
            color: "rgb(17, 205, 239)",
            width: 4,
          },
          mode: "lines",
          name: "Peaks Line",
          type: "scatter",
          connectgaps: true,
          x: this.getTemperaturesList ? this.getTemperaturesList.map((item) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && item.timestamp) ? item.timestamp : item.date)) : [],
          y: this.getTemperaturesList ? this.getTemperaturesList.map((item) => (item.temperature ? item.temperature.toFixed(2) : null)) : [],
          xaxis: "x",
          yaxis: "y",
          opacity: 0.9,
          textfont: {},
        },
      ];
    },
  },
  async mounted() {
    // Verifico la dimensione della finestra e aggiungo listener per gestire il ridimensionamento
    this.responsiveScreenHandler(mql);
    mql.addEventListener("change", () => {
      this.responsiveScreenHandler;
    });
  },
};
</script>
<style scoped>
h2 {
  font-size: 0.9em;
}
.over {
  display: flex;
  width: 96vw;
  min-width: 1100px;
  margin-right: 2vw;
  margin-left: 2vw;
}

.download-btn {
  cursor: pointer;
  grid-row: 1/3;
  height: 18px;
  justify-self: end;
  margin-right: 32px;
  background-color: rgb(33, 150, 243);
  padding: 10px;
  grid-column: 2;
  border-radius: 10px;
}

/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .over {
    width: 96vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
}

.temp-wrapper {
  position: relative;
  height: 490px;
  margin-top: 30px;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .temp-wrapper {
    height: 430px;
  }
}

#temps {
  width: 100%;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}

#tx-trend {
  grid-row: 1;
  grid-column: 1;
}

#temp-trend {
  grid-row: 1;
  grid-column: 2;
}

.main-wrap {
  margin-top: 20px;
  display: grid;
  margin-left: 60px;
  grid-template-columns: max-content;
}
.over-info > h2 {
  font-size: 1em;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 60px;
}
.over-info {
  grid-template-columns: 10fr min-content;
  text-align: left;
  position: relative;
}
.pointer {
  margin-left: 6px;
}
</style>
