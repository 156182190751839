<i18n>
{
   "en":{
      "title":"Energy consumption",
      "subTitle":"Use the following data to diagnose the causes affecting the energy consumption of the device",
      "dateRange":{
         "last-24-hours":"Last 24 hours",
         "last-7-days":"Last 7 days",
         "last-month":"Last month",
         "last-6-months":"Last 6 months",
         "last-year":"Last year",
         "entire-period": "Entire period"
      },
      "currentGraph":{
        "title": "Trend of current absorbed during acquisitions",
        "xTitle":"Time (Hr)",
        "yTitle":"current [mA]"
      },
      "samplingDurationGraph":{
        "title": "Trend of the duration of the acquisitions",
        "xTitle":"Time (Hr)",
        "yTitle":"Sampling duration [s]"
      }
   },
   "it":{
      "title":"Dettagli sul consumo energetico",
      "subTitle":"Utilizzare i seguenti dati per diagnosticare le cause che influenzano il consumo energetico del dispositivo",
      "dateRange":{
         "last-24-hours":"24 ore",
         "last-7-days":"Ultimi 7 giorni",
         "last-month":"Ultimo mese",
         "last-6-months":"Ultimi 6 mesi",
         "last-year":"Ultimo anno",
         "entire-period": "Intero periodo"
      },
      "currentGraph":{
        "title": "Andamento della corrente assorbita durante le acquisizioni",
        "xTitle":"Time (Hr)",
        "yTitle":"corrente [mA]"
      },
      "samplingDurationGraph":{
        "title": "Andamento della durata delle acquisizioni",
        "xTitle":"Time (Hr)",
        "yTitle":"Durata delle acquisizioni [s]"
      }
   }
}
</i18n>

<template>
  <move-modal height="90vh" width="90vw" simple class="text-align-left">
    <move-spinner-2 v-if="fetchingData" soft></move-spinner-2>
    <div class="d-flex flex-direction-column" :style="{ height: '100%' }">
      <div class="d-flex flex-direction-column">
        <div
          class="d-flex align-items-center justify-content-space-between bordered pb16 mb16"
          :style="{ width: '100%' }"
        >
          <move-text h2>
            {{ $t("title") }}
          </move-text>
          <move-btn @click="$emit('click-close')" icon>
            <font-awesome-icon icon="fa-solid fa-xmark" size="xl" />
          </move-btn>
        </div>
        <move-text p secondary class="mb32">
          {{ $t("subTitle") }}
        </move-text>
        <move-btn-list class="mb32 w-fit" large>
          <move-btn-item
            class="w150"
            :active="selectedRange === 'last-24-hours'"
            @click="selectedRange = 'last-24-hours'"
            bordered
          >
            <move-text p> {{ $t("dateRange.last-24-hours") }} </move-text>
          </move-btn-item>
          <move-btn-item
            class="w150"
            :active="selectedRange === 'last-7-days'"
            @click="selectedRange = 'last-7-days'"
            bordered
          >
            <move-text p> {{ $t("dateRange.last-7-days") }} </move-text>
          </move-btn-item>
          <move-btn-item
            class="w150"
            :active="selectedRange === 'last-month'"
            @click="selectedRange = 'last-month'"
            bordered
          >
            <move-text p> {{ $t("dateRange.last-month") }} </move-text>
          </move-btn-item>
          <move-btn-item
            class="w150"
            :active="selectedRange === 'last-6-months'"
            @click="selectedRange = 'last-6-months'"
            bordered
          >
            <move-text p> {{ $t("dateRange.last-6-months") }} </move-text>
          </move-btn-item>
          <move-btn-item
            class="w150"
            :active="selectedRange === 'last-year'"
            @click="selectedRange = 'last-year'"
            bordered
          >
            <move-text p> {{ $t("dateRange.last-year") }} </move-text>
          </move-btn-item>
          <move-btn-item
            class="w150"
            :active="selectedRange === 'entire-period'"
            @click="selectedRange = 'entire-period'"
            bordered
          >
            <move-text p> {{ $t("dateRange.entire-period") }} </move-text>
          </move-btn-item>
        </move-btn-list>
      </div>

      <div class="d-flex flex-direction-column graph-container scroll-thin">
        <move-text p b> {{ $t('currentGraph.title') }} </move-text>
        <MoveGraph
          :height="500"
          :xTitle="getCurrentAxisTitle.xTitle"
          :yTitle="getCurrentAxisTitle.yTitle"
          :traces="getCurrentTrace"
        />
        <move-text p b> {{ $t('samplingDurationGraph.title') }} </move-text>
        <MoveGraph
          :height="500"
          :xTitle="getSamplingDurationAxisTitle.xTitle"
          :yTitle="getSamplingDurationAxisTitle.yTitle"
          :traces="getSamplingDurationTrace"
        />
      </div>
    </div>
  </move-modal>
</template>

<script>
import MoveSpinner2 from "../../components/ui_V2/MoveSpinner2.vue";
import { mapGetters } from "vuex";
import api from "../../services/api";
import * as moment from "moment";

const LAST_24_HOURS = "last-24-hours";
const LAST_7_DAYS = "last-7-days";
const LAST_MONTH = "last-month";
const LAST_6_MONTHS = "last-6-months";
const LAST_YEAR = "last-year";
const ENTIRE_PERIOD = "entire-period";

export default {
  name: "EnergyConsumptionDetails",
  components: { MoveSpinner2 },
  data() {
    return {
      baseTrace: {
        connectgaps: true,
        /* Trace di base a cui vengono aggiunti attributi in base al grafico */
        line: {
          color: "rgb(17, 205, 239)",
          width: 2
        },
        marker: {
          color: "rgb(255, 255, 255)",
          size: 8
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {}
      },
      selectedRange: LAST_24_HOURS,
      analogV2Data: [],
      fetchingData: false
    };
  },
  computed: {
    ...mapGetters({
      getSelectedSensor: "eventdetails/getSelectedSensor"
    }),

    getSamplingDurationAxisTitle() {
      return {
        xTitle: this.$t("samplingDurationGraph.xTitle"),
        yTitle: this.$t("samplingDurationGraph.yTitle")
      };
    },

    getCurrentAxisTitle() {
      return {
        xTitle: this.$t("currentGraph.xTitle"),
        yTitle: this.$t("currentGraph.yTitle")
      };
    },

    getDateRange() {
      let startDate = moment().toDate()
      const endDate = moment().toDate();

      switch (this.selectedRange) {
        case LAST_24_HOURS:
          startDate = moment().subtract(24, "hours").toDate();
          break;
        case LAST_7_DAYS:
          startDate = moment().subtract(7, "days").toDate();
          break;
        case LAST_MONTH:
          startDate = moment().subtract(1, "months").toDate();
          break;
        case LAST_6_MONTHS:
          startDate = moment().subtract(6, "months").toDate();
          break;
        case LAST_YEAR:
          startDate = moment().subtract(1, "years").toDate();
          break;
        case ENTIRE_PERIOD:
          startDate = moment().subtract(10, "years").toDate();
          break;
        default:
          break;
      }
      return [startDate, endDate];
    },

    getSamplingDurationTrace() {
      let trace = JSON.parse(JSON.stringify(this.baseTrace));
      trace.x = this.analogV2Data.map(
        event => new Date(event.timestamp || event.date)
      );
      trace.y = this.analogV2Data.map(event => event.samplingDuration === undefined ? null : event.samplingDuration);
      return [trace];
    },

    getCurrentTrace() {
      let trace = JSON.parse(JSON.stringify(this.baseTrace));
      trace.x = this.analogV2Data.map(
        event => new Date(event.timestamp || event.date)
      );
      trace.y = this.analogV2Data.map(event => event.current === undefined ? null : event.current);
      return [trace];
    }
  },
  methods: {
    async fetchAnalogData() {
      this.fetchingData = true;
      try {
        this.analogV2Data = await api.getAnalogReadings(
          this.getSelectedSensor.eui,
          this.getDateRange[0],
          this.getDateRange[1]
        );
      } catch (e) {
        console.log(e.message);
      }
      this.fetchingData = false;
    }
  },
  watch: {
    selectedRange: async function() {
      await this.fetchAnalogData();
    }
  },
  async mounted() {
    await this.fetchAnalogData();
  }
};
</script>

<style scoped>
.graph-container {
  overflow-x: hidden;
  overflow-y: overlay;
}

.bordered {
  border-bottom: 1px solid #ccc;
}
</style>
