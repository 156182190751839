<template>
  <div class="over">
    <!-- Vibrations FFT -->
    <div id="temps" class="temp-wrapper noselect">
      <div class="infoi" v-show="loadingCount !== 0 || this.getDispList.length == 0">
        <h2 v-if="this.getDispList.length === 0 && loadingCount === 0">{{ $t("message.noDataInRange") }}...</h2>
        <h2 v-else>{{ $t("message.loading") }}...</h2>
      </div>
      <div class="main-wrap over-info">
        <div style="display: flex;">
          <h2>{{ $t("graphTitlesDetails.deckFreqResponse") }}</h2>
          <div>
            <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
            <DeckFFTBalloon :visible="isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
          </div>
        </div>
        <p class="first-block">{{ $t("graphSubTitlesDetails.deckFreqResponse") }}</p>
        <img @click="downloadFFT" class="download-btn" src="../../../../public/assets/download.svg" />
      </div>
      <div class="disp-plot-wrapper">
        <LinePlot :height="this.plotHeight" :traces="this.getTraces" :xTitle="$t('dataType.frequency') + ' (Hz)'" :yTitle="$t('dataType.amplitudeSpectrum') + ' (mm/Hz)'" :xRange="[0, maxFreq]" v-if="this.getFFT" />
      </div>
    </div>
  </div>
</template>
<script>
import LinePlot from "../../graphs/LinePlot.vue";
import { mapGetters, mapActions } from "vuex";
import { exportCSVFile } from "../../../helpers/download.js";
import DeckFFTBalloon from "../../tutorial/balloons/details/FreqRespDeckBl.vue";

var mql = window.matchMedia("screen and (max-width: 1499px)");

export default {
  name: "fft-module",
  components: {
    LinePlot,
    DeckFFTBalloon,
  },
  data() {
    return {
      fetchingQueue: [],
      loadingCount: 0,
      isInfoVisible: false,
      maxFreq: 25, // Max freq. di default per l'FFT 
      plotHeight: 490,
    };
  },
  methods: {
    ...mapActions({
      fetchFFT: "eventdetails/fetchFFT",
    }),
    responsiveScreenHandler: function (e) {
      // Modifico l'attributo 'height' del grafico in base alla dimensione della finestra, vedere 'mql'
      if (e.matches) {
        this.plotHeight = 350;
      } else {
        this.plotHeight = 490;
      }
    },
    async fetchDispFFT() {
      console.log("PreLoadingCount", this.loadingCount);
      const myLoadingID = this.loadingCount + 1;
      this.loadingCount = myLoadingID;
      //this.colorsMap = [];
      this.fetchingQueue.push({ id: myLoadingID, timestamp: new Date().getTime() });
      this.fetchingQueue.sort((b, a) => a.timestamp - b.timestamp);
      //console.log("Queue before", this.fetchingQueue);
      await this.fetchFFT();
      // Se l'ultima chiamata effettuata sono io
      if (this.fetchingQueue[0].id === myLoadingID) {
        //this.loadingCount = 0;
        console.log("Last FFT!");
      }
      this.fetchingQueue = this.fetchingQueue.filter((ld) => ld.id != myLoadingID);
      this.loadingCount -= 1;
      console.log("LastLoadingCount", this.loadingCount);
    },
    /* Scarico .CSV */
    downloadFFT() {
      if (this.loadingCount === 0) {
        let events = [];
        for (let i = 0; i < this.getFFT.freqs.length; i++) {
          events.push({
            eui: this.getSelectedSensor.eui.substr(this.getSelectedSensor.eui.length - 5),
            frequency: this.getFFT.freqs[i],
            amplitude: this.getFFT.magnitudes[i],
          });
        }
        let headers = {
          eui: "Device",
          frequency: "Frequency",
          amplitude: "Amplitude",
        };
        let title = "FFT " + this.getSelectedSensor.eui.substr(this.getSelectedSensor.eui.length - 5);

        exportCSVFile(headers, events, title);
      }
    },
  },
  computed: {
    ...mapGetters({
      getFFT: "eventdetails/getFFT",
      getDispDetails: "eventdetails/getDisplacementDetails",
      getDispList: "eventdetails/getDisplacementList",
      getSelectedSensor: "eventdetails/getSelectedSensor",
    }),
    getTraces: function () {
      return [
        {
          uid: "34534sa",
          line: {
            color: "rgb(17, 205, 239)",
            width: 4,
          },
          mode: "lines",
          name: "Peaks Line",
          type: "scatter",
          x: this.getFFT.freqs,
          y: this.getFFT.magnitudes,
          xaxis: "x",
          yaxis: "y",
          opacity: 0.9,
          textfont: {},
        },
      ];
    },
  },
  async mounted() {
    // Verifico la dimensione della finestra e aggiungo listener per gestire il ridimensionamento
    this.responsiveScreenHandler(mql);
    mql.addEventListener("change", () => {
      this.responsiveScreenHandler;
    });
  },
  watch: {
    getDispDetails: {
      handler: async function (newValue, oldValue) {
        if (newValue && (!oldValue || (oldValue && newValue._id != oldValue._id))) {
          // Init and Date already ok
          await this.fetchDispFFT();
          const payloadLength = newValue.payload.dati.length;
          // Adeguo il range di freq. sull'asse X in base alla revisione del Deck
          if (payloadLength === 1600){
            // Rev 2.0
            this.maxFreq = 25;
          } else if (payloadLength === 3000){
            // Rev 1.0
            this.maxFreq = 50;
          } else {
            // Impossibile (fallback)
            this.maxFreq = 50;
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 0.9em;
}
.over {
  min-width: 600px;
  width: 100%;
  column-gap: 2%;
  display: grid;
  grid-template-columns: 49% 49%;
}

.temp-wrapper {
  position: relative;
  height: 580px;
  margin-top: 30px;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

.download-btn {
  cursor: pointer;
  grid-row: 1/3;
  height: 18px;
  justify-self: end;
  margin-right: 32px;
  background-color: rgb(33, 150, 243);
  padding: 10px;
  grid-column: 2;
  border-radius: 10px;
}

/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .temp-wrapper {
    height: auto;
    min-height: 430px;
  }
}
#temps {
  width: 100%;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}

#tx-trend {
  grid-row: 1;
  grid-column: 1;
}

#temp-trend {
  grid-row: 1;
  grid-column: 2;
}
.main-wrap {
  margin-top: 20px;
  display: grid;
  margin-left: 60px;
  grid-template-columns: max-content;
}
.over-info > h2 {
  font-size: 1em;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  font-size: 0.8em;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info {
  text-align: left;
  position: relative;
}
.pointer{
  margin-left: 6px;
}
</style>
