<i18n>
{
  "en": {
      "title": "PPV - Peak Particle Velocity",
      "subTitle": "The PPV represents the maximum of the modulus of the velocity vector recorded in the time interval considered. The modulus of the velocity vector is calculated by simultaneously taking into account the three components (x,y,z). For example, if you set the sensor cadence parameter to 2 minutes, the PPV is calculated over a time window of 2 minutes."
  },
  "it": {
      "title": "PPV - Peak Particle Velocity",
      "subTitle": "La PPV rappresenta il massimo del modulo del vettore velocità registrato nell’intervallo di tempo considerato. Il modulo del vettore velocità è calcolato tenendo conto simultaneamente delle tre componenti (x,y,z). Impostando ad esempio il parametro cadenza del sensore a 2 minuti, la PPV è calcolata su una finestra temporale di 2 minuti."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "ppvVibBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 560px;
}
</style>
