<i18n>
{
  "en": {
    "structureTitle": "Events",
    "structureSubTitle": "Select a sensor to view all the data that have always been detected."

  },
  "it": {
    "structureTitle": "Eventi",
    "structureSubTitle": "Seleziona un sensore di cui visualizzare tutti i dati rilevati da sempre."
  }
}
</i18n>
<template>
  <div>
    <PageTemplate @needToReloadEvents="onNeedToReloadEvents">
      <template v-slot:nav>
        <DateRangeSelect
          :initDateRange="getDateRange"
          @date-selected="setDateRange"
          @structure-selected="selectStructure"
          :compensable="getIsCompensable"
          :relativeMode="getSelectedSensor ? getRelativeMode : undefined"
          :selectedStructure="getStructureDetails"
          :availableStructures="getStructures"
          @set-relative-mode="setRelativeMode"
        />
      </template>
      <template v-slot:content>
        <!-- Modulo Struttura -->
        <Generic
          id="generic"
          :title="$t('structureTitle')"
          :subTitle="$t('structureSubTitle')"
          v-if="getStructureDetails && getStructureDetails.type === 'generic'"
          :selectedSensorFromParent="getSelectedSensor"
          :selectedStructure="getStructureDetails"
          @sensor-selected="setSelectedSensor"
          @refresh="fetchStructureData"
        >
          <template v-slot:side-content>
            <EventsStat :structureDetails="getStructureDetails" />
          </template>
        </Generic>
        <Bridge id="bridge-inf" v-if="getStructureDetails && getStructureDetails.type != 'generic'" :selectedSensors="[getSelectedSensor]" @sensor-selected="setSelectedSensor" :showSideContent="true">
          <template v-slot:side-content>
            <EventsStat :structureDetails="getStructureDetails" />
          </template>
        </Bridge>

        <!-- Controllo Eventi -->
        <!-- <eventsControl id="bridge-ow" :structureDetails="getStructureDetails" v-if="false && loaded && getStructureDetails" /> -->
        <!-- Wrapper Esterno Eventi -->
        <div class="outer-event">
          <div class="inner-event">
            <!-- Grafici Eventi -->
            <EventDetails v-if="getStructureDetails" />
            <!-- Grafico FFT -->
            <VibrationsFFT v-if="getSelectedSensor && getSelectedSensor.type === 'deck'" />
            <AccelerationsFFT v-if="getSelectedSensor && getSelectedSensor.type === 'accelerometer' && getAccelerationType !== 'trigger_pkpk'" />

          </div>
          <!-- Selezione Evento -->
          <section v-if="getSelectedSensor && (getSelectedSensor.type === 'deck' || getSelectedSensor.type === 'accelerometer')">
            <EventsList id="list" v-if="getStructureDetails" :pagination="false" />
          </section>
        </div>
        <!-- Grafico Temperature -->
        <Temperatures v-if="getStructureDetails && getSelectedSensor && getSelectedSensor.type != 'analog-node'" />
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import EventsStat from "../../components/modules/events/EventsStats.vue";
import Generic from "../../components/structures/Generic.vue";
import Bridge from "../../components/structures/Bridge.vue";
import PageTemplate from "../PageTemplate.vue";
import DateRangeSelect from "../../components/ui/DRSelect.vue";
import VibrationsFFT from "../../components/modules/events/VibrationsFFT.vue";
import AccelerationsFFT from "../../components/modules/events/AccelerationsFFT.vue";
import EventsList from "../../components/modules/events/EventsList.vue";
import Temperatures from "../../components/modules/events/Temperatures.vue";
import EventDetails from "../../components/modules/events/EventDetails.vue";
import { getEntireDateRange } from "../../helpers/dates";

export default {
  name: "EventsV2",
  components: {
    PageTemplate,
    DateRangeSelect,
    EventsStat,
    Generic,
    Bridge,
    Temperatures,
    EventDetails,
    VibrationsFFT,
    AccelerationsFFT,
    EventsList,
  },
  data() {
    return {
      loaded: true,
      refreshingInterval: null,
    };
  },
  methods: {
    ...mapActions({
      fetchStructures: "structure/fetchStructures",
      selectStructure: "structure/selectStructure",
      fetchStructureData: "structure/fetchStructureData",
      setSelectedSensor: "eventdetails/setSelectedSensor",
      setDateRange: "eventdetails/setDateRange",
      logout: "auth/logout",
      fetchUserSettings: "user/fetchUserSettings",
      refreshSensorsStats: "structure/refreshSensorsStats",
      setRelativeMode: "eventdetails/setRelativeMode",
      fetchEventList: "eventdetails/fetchEventList",
      fetchDispDetails: "eventdetails/fetchDispDetails",
      fetchAccDetails: "eventdetails/fetchAccDetails",
      setLoadingStatus: "eventdetails/setLoadingStatus",
    }),
    checkIfAlreadyPositioned(sensor) {
      return (
        (sensor.bridgeSpan != undefined && sensor.bridgeSide != undefined && sensor.bridgeSpan != null && sensor.bridgeSide != null) ||
        (sensor.position && sensor.position.x != null && sensor.position.x != undefined && sensor.position.y != null && sensor.position.y != undefined)
      );
    },
    async onNeedToReloadEvents(){
      await this.fetchEventList()
    }
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSensors: "structure/getSensors",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getSensorType: "eventdetails/getSensorType",
      getSelectedSensor: "eventdetails/getSelectedSensor",
      getUserType: "user/getUserType",
      getRelativeMode: "eventdetails/getRelativeMode",
      getDateRange: "eventdetails/getDateRange",
      getAccelerationDetails: "eventdetails/getAccelerationDetails",
      getDispList: "eventdetails/getDisplacementList",
      getEventsCounter: "eventdetails/getEventsCounter",
      getTemperaturesList: "eventdetails/getTemperaturesList",
      getCracks: "eventdetails/getCracks",
      getAccelerations: "eventdetails/getAccelerations",
      getAnalogReadings: "eventdetails/getAnalogReadings",
      getTilts: "eventdetails/getTilts",
      getIsThereData: "eventdetails/getIsThereData",
      getLoadingStatus: "eventdetails/getLoadingStatus",
      getBaseValue: "eventdetails/getBaseValue",
      getUserInterface: "user/getInterface",
    }),
    getAccelerationType(){
      return this.getAccelerationDetails && this.getAccelerationDetails.type ? this.getAccelerationDetails.type : null
    },
    getIsCompensable(){
      return this.getSelectedSensor && (this.getSelectedSensor.type === 'tiltmeter' || (this.getSelectedSensor.type === 'analog-node' && this.getSelectedSensor.rev === '2.0') )
    }
  },
  async mounted() {
    await this.setDateRange(getEntireDateRange((new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)), new Date()));
    // Viene effettuato il fetch delle strutture
    /* await this.fetchStructures();
    await this.fetchUserSettings();
    if (!this.getSelectedStructure) {
      await this.selectStructure(this.getStructures[0]);
    } */
  },
  watch: {
    getSelectedSensor: {
      handler: async function(newSensor, oldSensor) {
        // Init and Date already ok
        if ((newSensor && oldSensor && newSensor.eui != oldSensor.eui) || !oldSensor) {
          this.loaded = false;
          await this.fetchEventList();
          if (newSensor.type === "deck" && this.getDispList && this.getDispList.length > 0) await this.fetchDispDetails(this.getDispList[0]._id);
          if (newSensor.type === "accelerometer" && this.getAccelerations && this.getAccelerations.length > 0) await this.fetchAccDetails(this.getAccelerations[0]._id);
          this.loaded = true;
          this.firstExec = false;
        }
      },
      deep: true,
    },
    /* getSensors: {
      handler: async function (newValue, oldValue) {
        // Init and Sensor already ok{
        //await this.setEventsSensors(this.deckList[0]);
        if (newValue && oldValue) {
          console.log("getSensors")
          await this.fetchEventList();
          if (this.getDispList && this.getDispList.length > 0) await this.fetchDispDetails(this.getDispList[0]._id);
          if (this.getAccelerations && this.getAccelerations.length > 0) await this.fetchAccDetails(this.getAccelerations[0]._id);
        }
      },
    }, */
    getDateRange: {
      handler: async function(newVal, oldVal) {
        // Init and Sensor already ok
        if ((oldVal[0] && oldVal[1] && (newVal[0].getTime() != oldVal[0].getTime() || newVal[1].getTime() != oldVal[1].getTime())) || this.firstExec) {
          if (!this.firstExec) {
            this.loaded = false;
            await this.fetchEventList();
            if (this.getDispList && this.getDispList.length > 0) await this.fetchDispDetails(this.getDispList[0]._id);
            if (this.getAccelerations && this.getAccelerations.length > 0) await this.fetchAccDetails(this.getAccelerations[0]._id);
            this.loaded = true;
          }
          this.firstExec = false;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
#status-row {
  width: 92%;
  margin: auto;
  display: grid;
  grid-template-columns: 6.5fr 3.5fr;
  column-gap: 20px;
  grid-template-rows: min-content;
}
#generic {
  margin-top: 20px;
}
#bridge-ow {
  max-width: 100vw;
}

* {
  font-size: 1em;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
}
/* NavBar */
#navbar {
  transition: transform 0.3s;
  position: fixed;
  width: 100%;
  min-width: 840px;
  z-index: 999999;
}
#general-info {
  height: 60px;
  background-color: transparent;
  width: 100%;
  top: 50px;
  left: 0;
  position: absolute;
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}
.event-details {
  position: absolute;
  display: grid;
  background-color: rgb(32, 34, 47);
  font-size: 1em;
  padding-top: 110px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  width: 100%;
  right: 0px;
  /* Transizioni usate per la Sidebar */
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}
/* Lista Eventi */
#list {
  position: relative;
  width: 25vw;
  min-width: 345px;
  height: 100%;
  margin-left: 30px;
  overflow: hidden;
}

/* Wrapper Lista Eventi */
section {
  position: sticky;
  top: 0px;
  left: 0;
  height: 100vh;
  margin-top: 30px;
  z-index: 2;
}

/* Wrapper Grafici Disp/FFT */
.inner-event {
  width: 100%;
  min-width: 800px;
}

/* Wrapper Lista Eventi + Grafici Disp/FFT */
.outer-event {
  display: flex;
  width: 96vw;
  min-width: 1100px;
  margin-right: 2vw;
  margin-left: 2vw;
}

/* Più di 1500 */
@media all and (min-width: 1500px) {
  .event-details {
    font-size: 1em;
  }
}
/* Tra 600 e 1499 */
@media all and (min-width: 300px) {
  .event-details {
    font-size: 0.82em;
  }
  section {
    height: auto;
  }
}

/* NavBar */
#navbar {
  transition: all 0.3s;
  position: fixed;
  width: 100%;
  min-width: 840px;
  margin-bottom: 50px;
}

/* Loading Transition */
.load-enter,
.load-leave-to {
  opacity: 0;
}
.load-enter-active {
  transition: all 0.3s ease;
}
.load-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

#set {
  z-index: 12;
}

#monitor,
#analog-node {
  z-index: 12;
}
</style>
