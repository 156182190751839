<template>
  <div class="module-wrapper">
    <EventList
      v-if="this.events"
      :events="this.events"
      :useTimestamp="useTimestampFieldBySensor(this.getSelectedSensor)"
      :disableEventDeletion="getUserType === 'sub-user'"
      @event-selected="eventSelected"
      @event-download="downloadItem"
      @event-delete="eventDelete"
    />
  </div>
</template>
<script>
import EventList from "../../ui/InnerEventListStandard.vue";
import { mapGetters, mapActions } from "vuex";
import { useTimestampFieldBySensor } from "../../../helpers/sensors";
import api from "../../../services/api";

export default {
  name: "event-list",
  components: {
    EventList,
  },
  methods: {
    ...mapActions({
      fetchAccDetails: "eventdetails/fetchAccDetails",
      fetchDispDetails: "eventdetails/fetchDispDetails",
      fetchEventList: "eventdetails/fetchEventList",
    }),
    useTimestampFieldBySensor: useTimestampFieldBySensor,
    eventSelected: async function(id) {
      if (this.getSelectedSensor.type === "deck") {
        this.fetchDispDetails(id);
      } else if (this.getSelectedSensor.type === "accelerometer") {
        this.fetchAccDetails(id);
      }
    },
    eventDelete: async function(eventID) {
      eventID;
      if (confirm(this.$t("message.irreversibleWarning"))) {
        if (this.getSelectedSensor.type === "deck") {
          await api.deleteVibrationEvent(eventID);
          await this.fetchEventList();
        } else if (this.getSelectedSensor.type === "accelerometer") {
          await api.deleteAccelerationEvent(eventID);
          await this.fetchEventList();
        }
      }
    },
    /* Scarica in formato .txt l'evento di ID eventID */
    downloadItem: async function(eventID) {
      let payloadText;
      let label;
      if (this.getSelectedSensor.type === "deck") {
        const event = await api.getDisplacementsDetails(eventID, this.getSelectedSensor.eui);
        label = event.eui + "_" + event.date;
        const payload = event.payload.dati;
        payloadText = payload.join("\r\n");
      }
      if (this.getSelectedSensor.type === "accelerometer") {
        const event = await api.getAccelerationDetails(eventID, this.getSelectedSensor.eui);
        let rows = [];
        label = event.eui + "_" + event.date;
        for (let i = 0; i < Math.min(event.acceleration.x.length, event.acceleration.y.length, event.acceleration.z.length); i++) {
          rows.push(event.acceleration.x[i] * 1000 + "," + event.acceleration.y[i] * 1000 + "," + event.acceleration.z[i] * 1000);
        }
        payloadText = rows.join("\r\n");
      }
      const blob = new Blob([payloadText], { type: "application/txt" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label + ".txt";
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  computed: {
    ...mapGetters({
      getAccelerationsList: "eventdetails/getAccelerations",
      getDisplacementList: "eventdetails/getDisplacementList",
      getSelectedSensor: "eventdetails/getSelectedSensor",
      getUserType:"user/getUserType"
    }),
    events: function() {
      let events;
      if (this.getSelectedSensor.type === "deck") {
        events = this.getDisplacementList;
      } else if (this.getSelectedSensor.type === "accelerometer") {
        events = this.getAccelerationsList;
      }
      return events;
    },
  },
};
</script>
<style scoped>
.module-wrapper {
  position: relative;
  border-radius: 10px;
  display: grid;
  align-content: center;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
}
</style>
