<i18n>
{
  "en": {
    "summary": "Summary",
    "allarm": "Allarm",
    "triggerMode": "Threshold sampling"
  },
  "it": {
    "summary": "Riepilogo",
    "allarm": "Allarme",
    "triggerMode": "Trigger Mode"
  }
}
</i18n>

<template>
  <div class="stat-block noselect">
    <div class="overview">
      <div class="d-flex align-items-center">
        <h2 class="mr16">{{ $t("title.dataSummary") }}:</h2>
      </div>
      <!-- Event counter -->
      <div class="data-info" v-if="getSensorType != 'vibrometer'">
        <p class="data-label">{{ $t("message.counterEvents") }}</p>
        <p class="data-value">{{ this.getEventsCounter || " - " }}</p>
      </div>
      <div class="data-info" v-if="getSensorType != 'vibrometer'">
        <p class="data-label">{{ $t("message.dailyAverageEvents") }}</p>
        <p class="data-value">{{ this.eventsMeanPerDay || " - " }}</p>
      </div>
      <div class="data-info" v-if="getSensorType != 'analog-node'">
        <p class="data-label">{{ $t("message.minTemp") }}</p>
        <p class="data-value">
          {{ this.minTemperature ? this.minTemperature + " °C" : " - " }}
        </p>
      </div>
      <!-- Temperature option -->
      <div class="data-info" v-if="getSensorType != 'analog-node'">
        <p class="data-label">{{ $t("message.maxTemp") }}</p>
        <p class="data-value">
          {{ this.maxTemperature ? this.maxTemperature + " °C" : " - " }}
        </p>
      </div>
      <!-- Allarm option -->
      <div
        class="data-info"
        v-if=" getSensorType === 'analog-node' && getSelectedSensor && getSelectedSensor.rev === '2.0'"
      >
        <p class="data-label">{{ $t("allarm") }}</p>
        <p class="data-value">
          {{ this.getNodeV2Allarm0 && this.getNodeV2Allarm0.enabled ? "On" : "Off" }}
        </p>
      </div>
      <!-- Trigger mode -->
      <div
        class="data-info"
        v-if=" getSensorType === 'analog-node' && getSelectedSensor && getSelectedSensor.rev === '2.0'"
      >
        <p class="data-label">{{ $t("triggerMode") }}</p>
        <p class="data-value">
          {{ this.getNodeV2AccTriggerMode ? "On" : "Off" }}
        </p>
      </div>
      <!-- Download csv -->
      <div class="buttons-wrapper">
        <div>
          <label for="sel-sensor-only">{{ $t("message.dataOf") }}:</label>
          <select
            name="sel-sensor-only"
            id="sel-sensor-only"
            v-model="downloadSelSensorOnly"
          >
            <option :value="true">{{ $t("message.selSensor") }}</option>
            <template v-if="getSensorType !== 'analog-node'">
              <option :value="false">{{ $t("message.allSensors") }}</option>
            </template>
          </select>
        </div>
        <div>
          <label for="desc-date">{{ $t("message.dateSorting") }}:</label>
          <select name="desc-date" id="sdesc-date" v-model="downloadDescDate">
            <option :value="true">{{ $t("message.descending") }}</option>
            <option :value="false">{{ $t("message.ascending") }}</option>
          </select>
        </div>
        <button
          class="download-button"
          @click="downloadEvents"
          :disabled="!this.getIsThereData || !this.getLoadingStatus"
        >
          <img
            class="down-small"
            src="../../../../public/assets/download-small-white.svg"
            width="24px"
          />
          {{ loadingMex || "Download" }}
          <span>{{ $t("summary") }}.csv</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/* 

Modulo in EVENT_DETAILS che mostra le statistiche relativi agli eventi visualizzati e ne consente il download.

*/
import { mapGetters, mapActions } from "vuex";
import { exportCSVFile } from "../../../helpers/download.js";
import { useTimestampFieldBySensor, getSensorFromEui } from "../../../helpers/sensors";
import { daysBetween } from "../../../helpers/dates";
import math from "../../../helpers/math.js";
import api from "../../../services/api";
import { getShortEui } from "../../../helpers/sensors";

export default {
  name: "EventsStat",
  data() {
    return {
      firstExec: true,
      downloadSelSensorOnly: true,
      downloadDescDate: true
    };
  },
  computed: {
    ...mapGetters({
      getDateRange: "eventdetails/getDateRange",
      getSensors: "structure/getSensors",
      getDispList: "eventdetails/getDisplacementList",
      getSelectedSensor: "eventdetails/getSelectedSensor",
      getRelativeMode: "eventdetails/getRelativeMode",
      getEventsCounter: "eventdetails/getEventsCounter",
      getTemperaturesList: "eventdetails/getTemperaturesList",
      getCracks: "eventdetails/getCracks",
      getPPVs: "eventdetails/getPPVs",
      getAccelerations: "eventdetails/getAccelerations",
      getAnalogReadings: "eventdetails/getAnalogReadings",
      getTilts: "eventdetails/getTilts",
      getSensorType: "eventdetails/getSensorType",
      getIsThereData: "eventdetails/getIsThereData",
      getLoadingStatus: "eventdetails/getLoadingStatus",
      getBaseValue: "eventdetails/getBaseValue",
      getUserInterface: "user/getInterface"
    }),
    getNoveV2UserConfig() {
      return this.getSelectedSensor && this.getSelectedSensor.userConfig;
    },
    getNodeV2Channel0() {
      return (
        this.getNoveV2UserConfig &&
        this.getNoveV2UserConfig.channels &&
        this.getNoveV2UserConfig.channels.length &&
        this.getNoveV2UserConfig.channels[0]
      );
    },
    getNodeV2Allarm0() {
      return (
        this.getNodeV2Channel0 &&
        this.getNodeV2Channel0.alerts &&
        this.getNodeV2Channel0.alerts.length &&
        this.getNodeV2Channel0.alerts[0]
      );
    },
    getNodeV2AccTriggerMode() {
      return (
        this.getNoveV2UserConfig && this.getNoveV2UserConfig.accTriggerMode
      );
    },
    mesUnit() {
      return this.getUserInterface ? this.getUserInterface.dimensions : null;
    },
    getVelocityUnit() {
      return (this.mesUnit || {}).velocity || "mm/s";
    },
    relativeMode: {
      // Getter & Setter per relativeMode
      get() {
        return this.getRelativeMode;
      },
      set(value) {
        this.setRelativeMode(value);
      }
    },
    loadingMex: function() {
      // Messaggio da visualizzare nel bottone di Download Eventi
      let mex;
      if (!this.getLoadingStatus) mex = "Loading...";
      else if (!this.getIsThereData && this.getLoadingStatus)
        mex = this.$t("message.noData");
      return mex;
    },
    compensable: function() {
      let compensable = false;
      if (
        this.getSelectedSensor &&
        (this.getSelectedSensor.type === "tiltmeter" ||
          this.getSelectedSensor.type === "crackmeter")
      )
        compensable = true;
      return compensable;
    },
    eventsMeanPerDay: function() {
      let mean = null;
      if (
        this.getEventsCounter &&
        this.getDateRange[0] &&
        this.getDateRange[1]
      ) {
        let counter = this.getEventsCounter;
        // To calculate the no. of days between two dates
        var Difference_In_Days = daysBetween(
          this.getDateRange[0],
          this.getDateRange[1]
        );
        mean = (counter / (Difference_In_Days + 1)).toFixed(2);
      }
      return mean;
    },
    maxTemperature: function() {
      let maxTemp;
      let temps = this.getTemperaturesList
        ? this.getTemperaturesList.map(item => item.temperature)
        : null;
      if (temps && temps.length > 0) {
        maxTemp = Math.max.apply(null, temps).toFixed(1);
      }
      return maxTemp;
    },
    getNodeV2MaxTemperature(){
      const temperatures = this.getAnalogReadings.map(ar => ar.convertedTemperature === undefined ? null : ar.convertedTemperature).filter(t => t !== null);
      return Math.max(...temperatures)
    },
    minTemperature: function() {
      let minTemp;
      let temps = this.getTemperaturesList
        ? this.getTemperaturesList.map(item => item.temperature)
        : null;
      if (temps && temps.length > 0) {
        minTemp = Math.min.apply(null, temps).toFixed(1);
      }
      return minTemp;
    }
  },
  methods: {
    ...mapActions({
      setEventsSensors: "eventdetails/setSelectedSensor",
      setDateRange: "eventdetails/setDateRange",
      fetchEventList: "eventdetails/fetchEventList",
      fetchDispDetails: "eventdetails/fetchDispDetails",
      setRelativeMode: "eventdetails/setRelativeMode",
      fetchAccDetails: "eventdetails/fetchAccDetails",
      setLoadingStatus: "eventdetails/setLoadingStatus"
    }),
    getAcualMainEvents: async function(selectedSensor) {
      // Ritorna header ed eventi da passare alla funzione che crea il .CSV
      let events = [];
      let headers = {};
      let title;
      let offset;
      let devEui = selectedSensor.eui;
      let type = selectedSensor.type;
      let subType = selectedSensor.subType;
      let mesUnit = this.mesUnit;
      let fetchedList;
      const sensors = this.getSensors
      switch (type) {
        case "deck":
          if (this.downloadSelSensorOnly) {
            fetchedList = this.getDispList;
          } else {
            await this.setLoadingStatus(false);
            const totalList = await Promise.all(
              this.getSensors
                .filter(sn => sn.type === type)
                .map(sn =>
                  api.getDisplacementsList(
                    sn.eui,
                    this.getDateRange[0],
                    this.getDateRange[1]
                  )
                )
            );
            await this.setLoadingStatus(true);
            fetchedList = [].concat(...totalList);
          }
          events = fetchedList
            .sort((a, b) =>
              this.downloadDescDate ? null : a.date.localeCompare(b.date)
            )
            .map(function(item) {
              return {
                eui: item.eui.substr(item.eui.length - 5),
                date: new Date(item.date).toLocaleString().replace(/,/g, ""),
                timestamp: item.timestamp
                  ? new Date(item.timestamp).toLocaleString().replace(/,/g, "")
                  : "",
                piccoMax: item.payload.piccoMax,
                piccoMin: item.payload.piccoMin,
                PkToPk: item.payload.piccoMax - item.payload.piccoMin
              };
            });
          headers = {
            eui: "Device",
            date: this.$t("message.date") + " (Locale)",
            timestamp: "Timestamp (Locale)",
            piccoMax: this.$t("message.maxPeak") + " (mm)",
            piccoMin: this.$t("message.minPeak") + " (mm)",
            PkToPk: this.$t("message.peakToPeak") + " (mm)"
          };
          title =
            "Report Deck " +
            (this.downloadSelSensorOnly
              ? devEui.substr(devEui.length - 5)
              : "");
          break;
        case "tiltmeter":
          if (this.downloadSelSensorOnly) {
            fetchedList = this.getTilts;
          } else {
            await this.setLoadingStatus(false);
            const totalList = await Promise.all(
              this.getSensors
                .filter(sn => sn.type === type)
                .map(sn =>
                  api.getTiltsList(
                    sn.eui,
                    this.getDateRange[0],
                    this.getDateRange[1],
                    !this.getRelativeMode,
                    sn.beamFormat
                  )
                )
            );
            await this.setLoadingStatus(true);
            fetchedList = [].concat(...totalList);
          }
          events = fetchedList
            .sort((a, b) =>
              this.downloadDescDate ? null : a.date.localeCompare(b.date)
            )
            .map(function(item) {
              const sensor = getSensorFromEui(sensors, item.eui);
              const date = (useTimestampFieldBySensor(sensor) && item.timestamp) || item.date
              return {
                eui: item.eui.substr(item.eui.length - 5),
                date: new Date(date).toLocaleString().replace(/,/g, ""),
                tiltPhi:
                  mesUnit && mesUnit.degrees === "mm/m"
                    ? math.degreesToMM(item.phiAngle).toFixed(6)
                    : item.phiAngle.toFixed(6),
                tiltTheta:
                  mesUnit && mesUnit.degrees === "mm/m"
                    ? math.degreesToMM(item.thetaAngle).toFixed(6)
                    : item.thetaAngle.toFixed(6),
                /*  tilt3D: mesUnit && mesUnit.degrees === "mm/m" ? math.degreesToMM(item.extraAngle).toFixed(6) : item.extraAngle.toFixed(6), */
                x: item.x,
                y: item.y,
                z: item.z
              };
            });
          headers = {
            eui: "Device",
            date: this.$t("message.date") + " (Locale)",
            tiltPhi:
              "Tilt Phi " +
              (mesUnit && mesUnit.degrees === "mm/m" ? "mm/m" : "°"),
            tiltTheta:
              "Tilt Theta " +
              (mesUnit && mesUnit.degrees === "mm/m" ? "mm/m" : "°"),
            /* tilt3D: "Tilt 3D " + (mesUnit && mesUnit.degrees === "mm/m" ? "mm/m" : "°"), */
            x: "X Axis Acceleration",
            y: "Y Axis Acceleration",
            z: "Z Axis Acceleration"
          };
          title =
            "Report Tiltmeter " +
            (this.downloadSelSensorOnly
              ? devEui.substr(devEui.length - 5)
              : "");
          break;
        case "vibrometer":
          if (this.downloadSelSensorOnly) {
            fetchedList = this.getPPVs.values;
          } else {
            await this.setLoadingStatus(false);
            const totalList = (
              await Promise.all(
                this.getSensors
                  .filter(sn => sn.type === type)
                  .map(sn =>
                    api.getPPVs(
                      sn.eui,
                      this.getDateRange[0],
                      this.getDateRange[1],
                      !this.getRelativeMode,
                      this.getVelocityUnit
                    )
                  )
              )
            ).values;
            await this.setLoadingStatus(true);
            fetchedList = [].concat(...totalList);
          }
          events = fetchedList
            .sort((a, b) =>
              this.downloadDescDate ? null : a.date.localeCompare(b.date)
            )
            .map(function(item) {
              return {
                eui: item.eui.substr(item.eui.length - 5),
                date: new Date(item.date).toLocaleString().replace(/,/g, ""),
                timestamp: new Date(item.timestamp)
                  .toLocaleString()
                  .replace(/,/g, ""),
                ppv: item.ppv,
                PCPV_X: item.amplitudes[0],
                PCPV_Y: item.amplitudes[1],
                PCPV_Z: item.amplitudes[2],
                fftAmplitudeX: item.fftAmplitudes[0],
                fftAmplitudeY: item.fftAmplitudes[1],
                fftAmplitudeZ: item.fftAmplitudes[2],
                freqX: item.fftFreqs[0],
                freqY: item.fftFreqs[1],
                freqz: item.fftFreqs[2]
              };
            });
          headers = {
            eui: "Device",
            date: this.$t("message.date") + " (Locale)",
            timestamp: "Timestamp (Locale)",
            ppv: `PPV [${this.getVelocityUnit}]`,
            PCPV_X: `PCPV X [${this.getVelocityUnit}]`,
            PCPV_Y: `PCPV Y [${this.getVelocityUnit}]`,
            PCPV_Z: `PCPV Z [${this.getVelocityUnit}]`,
            fftAmplitudeX: `FFT Amplitude X [(${this.getVelocityUnit})/Hz]`,
            fftAmplitudeY: `FFT Amplitude Y [(${this.getVelocityUnit})/Hz]`,
            fftAmplitudeZ: `FFT Amplitude Z [(${this.getVelocityUnit})/Hz]`,
            freqX: `FFT Freq X [Hz]`,
            freqY: `FFT Freq Y [Hz]`,
            freqz: `FFT Freq Z [Hz]`
          };
          title =
            "Report Vibrometro " +
            (this.downloadSelSensorOnly
              ? devEui.substr(devEui.length - 5)
              : "");
          break;
        case "accelerometer":
          if (this.downloadSelSensorOnly) {
            fetchedList = this.getAccelerations;
          } else {
            await this.setLoadingStatus(false);
            const totalList = await Promise.all(
              this.getSensors
                .filter(sn => sn.type === type)
                .map(sn =>
                  api.getAccelerationsList(
                    sn.eui,
                    this.getDateRange[0],
                    this.getDateRange[1]
                  )
                )
            );
            await this.setLoadingStatus(true);
            fetchedList = [].concat(...totalList);
          }
          events = fetchedList
            .sort((a, b) =>
              this.downloadDescDate
                ? null
                : (useTimestampFieldBySensor(this.getSelectedSensor) &&
                  a.timestamp
                    ? a.timestamp
                    : a.date
                  ).localeCompare(
                    useTimestampFieldBySensor(this.getSelectedSensor) &&
                      b.timestamp
                      ? b.timestamp
                      : b.date
                  )
            )
            .map(item => {
              return {
                eui: item.eui.substr(item.eui.length - 5),
                date: new Date(
                  useTimestampFieldBySensor(this.getSelectedSensor) &&
                  item.timestamp
                    ? item.timestamp
                    : item.date
                )
                  .toLocaleString()
                  .replace(/,/g, ""),
                accX: item.acceleration.xPeak * 1000,
                accY: item.acceleration.yPeak * 1000,
                accZ: item.acceleration.zPeak * 1000
              };
            });
          headers = {
            eui: "Device",
            date: this.$t("message.date") + " (Locale)",
            accX: "Pk-Pk X Acc (mg)",
            accY: "Pk-Pk Y Acc (mg)",
            accZ: "Pk-Pk Z Acc (mg)"
          };
          title =
            "Report Accelerometer " +
            (this.downloadSelSensorOnly
              ? devEui.substr(devEui.length - 5)
              : "");
          break;
        case "analog-node":
          if (this.getSelectedSensor && this.getSelectedSensor.rev === "1.0") {
            if (this.downloadSelSensorOnly) {
              fetchedList = this.getAnalogReadings;
            } else {
              await this.setLoadingStatus(false);
              const totalList = await Promise.all(
                this.getSensors
                  .filter(sn => sn.type === type)
                  .map(sn =>
                    api.getAnalogReadings(
                      sn.eui,
                      this.getDateRange[0],
                      this.getDateRange[1]
                    )
                  )
              );
              await this.setLoadingStatus(true);
              fetchedList = [].concat(...totalList);
            }
            events = fetchedList
              .sort((a, b) =>
                this.downloadDescDate ? null : a.date.localeCompare(b.date)
              )
              .map(function(item) {
                return {
                  eui: item.eui.substr(item.eui.length - 5),
                  date: new Date(item.date).toLocaleString().replace(/,/g, ""),
                  CH1: item.channelsData[0],
                  CH2: item.channelsData[1],
                  CH3: item.channelsData[2],
                  CH4: item.channelsData[3]
                };
              });
            headers = {
              eui: "Device",
              date: this.$t("message.date") + " (Locale)",
              CH1: "Channel 1",
              CH2: "Channel 2",
              CH3: "Channel 3",
              CH4: "Channel 4"
            };
            title =
              "Report Analog Node " +
              (this.downloadSelSensorOnly
                ? devEui.substr(devEui.length - 5)
                : "");
          } else if (this.getSelectedSensor && this.getSelectedSensor.rev === "2.0") {
            title = "Report Analog Node";
            if (this.downloadSelSensorOnly) {
              fetchedList = this.getAnalogReadings;
            }
            const acquisitionTypes = {
              scheduled: "programmed sampling",
              triggered_acc: "threshold sampling",
              scheduled_triggered_acc: "threshold sampling",
              triggered_ext: "triggered_ext"
            };
            const mesUnitsRaw = {
              "5V": "V",
              "12V": "V",
              "mV/V": "mV/V",
              NTC: "Ohm",
              "vibrating-wire": "Hz",
              "4-20": "mA",
              PTC: "Ohm",
              potentiometric: "%"
            };
            const type = this.getNodeV2Channel0.nativeType || null;
            const mesUnitRaw = type ? mesUnitsRaw[type] : "";
            const mesUnitCustom = this.getNodeV2Channel0.mesUnit || "";
            const shortEui =
              this.getSelectedSensor &&
              this.getSelectedSensor.eui &&
              getShortEui(this.getSelectedSensor.eui);
            events = fetchedList
              .sort((a, b) =>
                this.downloadDescDate ? null : a.date.localeCompare(b.date)
              )
              .map(item => {
                const timestamp =
                  item.timestamp || item.date
                    ? new Date(item.timestamp || item.date)
                        .toLocaleString()
                        .replace(/,/g, "")
                    : "";
                const acquisitionType = item.type
                  ? acquisitionTypes[item.type]
                  : "";
                return {
                  sensorType: item.rsc || "",
                  eui: shortEui,
                  timestamp,
                  probeConvertedData: item.value || "",
                  probeDataRaw: item.rawValue || "",
                  convertedInternalNtc: item.convertedTemperature || "",
                  ntcInternalNtc: item.probeTemperature || "",
                  acquisitionType,
                  vpkAxePeak:
                    (item.acceleration && item.acceleration.peakValue) || ""
                };
              });
            headers = {
              sensorType: "Sensor_Type",
              eui: "Dev_EUI",
              timestamp: "Timestamp",
              probeConvertedData: `Probe Converted Data (${mesUnitCustom})`,
              probeDataRaw: `Probe Raw Data (${mesUnitRaw})`,
              convertedInternalNtc:
                "Converted Internal NTC (°C) - if supported/enabled",
              ntcInternalNtc: "Raw Internal NTC - if supported/enabled (Ohm)",
              acquisitionType: "Acquisition_type",
              vpkAxePeak: "Vpk - AXE peak"
            };
            title = `Report Analog Node ${shortEui}`;
          }

          break;
        case "crackmeter":
          offset =
            this.getRelativeMode && this.getBaseValue ? this.getBaseValue : 0;
          if (this.downloadSelSensorOnly) {
            fetchedList = this.getCracks;
          } else {
            await this.setLoadingStatus(false);
            const totalList = await Promise.all(
              this.getSensors
                .filter(sn => sn.type === type)
                .map(sn =>
                  api.getCracksList(
                    sn.eui,
                    this.getDateRange[0],
                    this.getDateRange[1]
                  )
                )
            );
            await this.setLoadingStatus(true);
            fetchedList = [].concat(...totalList);
          }
          events = fetchedList
            .sort((a, b) =>
              this.downloadDescDate ? null : a.date.localeCompare(b.date)
            )
            .map(function(item) {
              let computedSample =
                subType === "loadcell"
                  ? (item.sample / (3.92 * Math.pow(10, -3)) - 6400) * 11.71875
                  : item.sample - offset;
              return {
                //id: item._id,
                eui: item.eui.substr(item.eui.length - 5),
                date: new Date(item.date).toLocaleString().replace(/,/g, ""),
                sample: computedSample
              };
            });
          headers = {
            eui: "Device",
            date: "Data (Locale)",
            sample: subType === "loadcell" ? "Load (N)" : "Sample (mm)"
          };
          title =
            "Report Crackmeter " +
            (this.downloadSelSensorOnly
              ? devEui.substr(devEui.length - 5)
              : "");
          break;
      }
      return this.getIsThereData
        ? { title: title, headers: headers, events: events }
        : null;
    },
    downloadEvents: async function() {
      const selectedSensor = this.getSelectedSensor;
      const eventsList = await this.getAcualMainEvents(selectedSensor);
      exportCSVFile(
        // Scarica Eventi del device (no temperature)
        eventsList.headers,
        eventsList.events,
        eventsList.title
      );
    }
  },
  watch: {
    getSelectedSensor: {
      handler: async function(newSensor, oldSensor) {
        // Init and Date already ok
        if (
          (newSensor && oldSensor && newSensor.eui != oldSensor.eui) ||
          !oldSensor
        ) {
          //this.loaded = false;
          //await this.fetchEventList();
          //if (this.getDispList && this.getDispList.length > 0) await this.fetchDispDetails(this.getDispList[0]._id);
          //if (this.getAccelerations && this.getAccelerations.length > 0) await this.fetchAccDetails(this.getAccelerations[0]._id);
          //this.loaded = true;
          //this.firstExec = false;
        }
      },
      deep: true
    },
    sensors: {
      handler: async function() {
        // Init and Sensor already ok{
        //await this.fetchEventList();
        //if (this.getDispList && this.getDispList.length > 0) await this.fetchDispDetails(this.getDispList[0]._id);
        //if (this.getAccelerations && this.getAccelerations.length > 0) await this.fetchAccDetails(this.getAccelerations[0]._id);
      }
    },
    getDateRange: {
      handler: async function(newVal, oldVal) {
        // Init and Sensor already ok
        if (
          (oldVal[0] &&
            oldVal[1] &&
            (newVal[0].getTime() != oldVal[0].getTime() ||
              newVal[1].getTime() != oldVal[1].getTime())) ||
          this.firstExec
        ) {
          if (!this.firstExec) {
            this.loaded = false;
            await this.fetchEventList();
            console.log("egomi");
            if (this.getDispList && this.getDispList.length > 0)
              await this.fetchDispDetails(this.getDispList[0]._id);
            if (this.getAccelerations && this.getAccelerations.length > 0)
              await this.fetchAccDetails(this.getAccelerations[0]._id);
            this.loaded = true;
          }
          this.firstExec = false;
        }
      },
      deep: true
    }
  }
  /* async mounted() {
    await this.setDateRange([new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000), new Date()]);
  }, */
};
</script>
<style scoped>
p,
label {
  font-size: 16px;
}
.download-button > br {
  display: block;
  margin: 10px 0;
}

.download-button > span {
  display: contents;
  font-weight: 500;
}
/* Root Wrapper */
.stat-block {
  width: 450px;
  z-index: 2;
  justify-items: center;
  align-content: center;
  background-color: rgb(45, 48, 65);
}
/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .stat-block {
    width: 350px;
  }
}

.info-group {
  display: flex;
  align-items: center;
  align-self: center;
  min-width: 450px;
}

.info-group > h2 {
  margin: 0;
  margin-left: 10px;
}

.info-group > p {
  margin: 0;
}

.overview {
  text-align: left;
  align-content: flex-start;
  padding: 40px 20px;
  box-sizing: border-box;
  z-index: 12;
  min-height: 430px;
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  color: white;
  background-color: rgb(30, 31, 43);
  padding: 20px;
  border-radius: 10px;
}

select {
  padding: 0;
  padding-right: 20px;
  margin: 5px 0;
  margin-left: 5px;
  font-weight: 700;
}

.data-info {
  height: 40px;
  background-color: rgb(30, 31, 43);
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
  display: flex;
}

.data-label {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  width: 70%;
}

.data-value {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
}

.download-icon {
  width: 100%;
  margin-top: 42px;
  display: flex;
  transition: transform 0.1s linear;
}

.download-button {
  width: 100%;
  margin-top: 20px;
  background-color: rgb(80, 84, 105);
  display: flex;
  border-radius: 10px;
  border: solid 3px white;
  height: 52px;
  justify-content: center;
  align-items: center;
  text-align: left;
  line-height: 17px;
  text-decoration: none;
  outline: none;
  color: #fff;
  font-family: inherit;
  font-weight: 700;
  transition: transform 0.1s linear;
}

.download-button:hover {
  background-color: rgb(9, 109, 177);
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.download-button:active {
  background-color: rgb(21, 146, 230);
  transform: translateY(3px);
}

.download-button:disabled:hover {
  background-color: rgb(80, 84, 105);
}

.download-button:disabled:active {
  background-color: rgb(80, 84, 105);
  transform: translateY(0px);
}

.down-small {
  margin-right: 12px;
}

@media all and (max-width: 1499px) and (min-width: 300px) {
  .stat-block {
    grid-template-columns: 70% 30%;
  }
}
</style>
