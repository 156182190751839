<i18n>
{
  "en": {
      "title": "Time Response of displacement",
      "subTitle": "Graph relating to the trend of a specific vibration detected by the sensor. Each event is described in a 30-second sampling and the oscillation is detected by the sensor in mm."

  },
  "it": {
      "title": "Time Response dello spostamente",
      "subTitle": "Grafico relativo all’andamento di una specifica vibrazione rilevata dal sensore. Ogni evento è descritto in un arco temporale di 30 secondi di campionamento e l’oscillazione viene rilevata dal sensore in mm."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "timeRespDeckBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 360px;
}
</style>
