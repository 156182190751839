<i18n>
{
  "en": {
    "vibrometerPPVAggregationMessage": "The graph is constructed by aggregating the data sent by the sensor at {range} intervals and choosing for each interval the data that has the highest value PPV.",
    "vibrometerPCPVAggregationMessage": "The graph is constructed by aggregating the data sent by the sensor at intervals of {range} and choosing for each interval, and for each axis, the data that presents the PCPV of maximum value.",
    "vibrometerFreqPeakAggregationMessage": "The graph is constructed by aggregating data at {range} intervals, the peak spectrum frequencies shown are those measured in correspondence to the PCPV data shown above.",
    "vibrometerAmpPeakAggregationMessage": "The graph is constructed by aggregating data at {range} intervals, the peak amplitudes of the spectrum shown are those measured in correspondence to the PCPV data shown above.",
    "infoNodeV2Data": "The Graph shows the measure provided by the probe connected to the node. If the setting mode is Raw Data, it shows the Raw Data detected by the probe. If the the Setting mode is Custom, the data is converted into the actual measure.",
    "nodeV2": {
      "dataTitle": "Single Channel Node",
      "dataSubtitle": "Trend of the probe connected to the selected Analog Node.",
      "dataInfo": "The graph displays the measurement provided by the probe connected to the node. If the mode is 'raw' the raw data provided by the probe is shown. If the mode is 'converted', the graph shows the actual measurement.",
      "ntcInfo": "Graph showing the data detected by the NTC inside the probe. If the setting mode is Raw Data, it shows the Raw Data detected by the NTC. If the the Setting mode is Custom, the data is converted into the actual measure.",
      "internalNtcTitle": "NTC",
      "internalNtcSubtitle": "Trend of the data detected by the NTC of the probe.",
      "temperatureTitle": "Temperature trend",
      "temperatureSubtitle": "Temperature trend of the internal NTC."
    }
  },
  "it": {
    "vibrometerPPVAggregationMessage": "Il grafico è costruito aggregando i dati inviati dal sensore ad intervalli di {range} e scegliendo per ogni intervallo il dato che presenta la PPV di valore massimo.",
    "vibrometerPCPVAggregationMessage": "Il grafico è costruito aggregando i dati inviati dal sensore ad intervalli di {range} e scegliendo per ogni intervallo, e per ogni asse, il dato che presenta la PCPV di valore massimo.",
    "vibrometerFreqPeakAggregationMessage": "Il grafico è costruito aggregando i dati ad intervalli di {range}, le frequenze del picco dello spettro riportate sono quelle misurate in corrispondenza al dato di PCPV mostrato sopra.",
    "vibrometerAmpPeakAggregationMessage": "Il grafico è costruito aggregando i dati ad intervalli di {range}, le ampiezze di picco dello spettro riportate sono quelle misurate in corrispondenza al dato di PCPV mostrato sopra.",
    "infoNodeV2Data": "Il grafico corrisponde alla misura fornita dalla sonda connessa al nodo. Se la modalità è dati grezzi, è mostrato il dato grezzo fornito dalla sonda. Se la modalità è Custom, il dato che appare nel grafico è convertito nella misura effettiva.",
    "nodeV2": {
      "dataTitle": "Single Channel Node",
      "dataSubtitle": "Andamento della sonda collegata al Single Channel Node selezionato.",
      "dataInfo": "Il grafico corrisponde alla misura fornita dalla sonda connessa al nodo. Se la modalità è 'grezzi' è mostrato il dato grezzo fornito dalla sonda. Se la modalità è 'convertiti', il grafico corrisponde alla misura effettiva.",
      "ntcInfo": "Grafico che mostra la misura fornita dall'NTC interno alla sonda. Se la modalità è dati grezzi, è mostrato il dato grezzo fornito dall'NTC. Se la modalità è Custom, il dato che appare nel grafico è convertito nella misura effettiva.",
      "internalNtcTitle": "NTC",
      "internalNtcSubtitle": "Andamento della temperatura dell’NTC interno.",
      "temperatureTitle": "Andamento delle temperatura",
      "temperatureSubtitle": "Andamento della temperatura rilevato dall’NTC della sonda."
    }
  }
}
</i18n>
<template>
  <div class="over">

    <!-- Wrapper -->
    <div id="temps" class="details-wrapper noselect" :class="{ large: getSensorType === 'accelerometer' }">

      <EnergyConsumption v-if="(getSelectedSensor && getSensorType === 'analog-node' && getSelectedSensor.rev === '2.0')" />

      <div class="infoi" v-show="!getLoadingStatus || !getIsThereData">
        <h2 v-if="!getIsThereData && getLoadingStatus">{{ $t("message.noDataInRange") }}...</h2>
        <h2 v-else>{{ $t("message.loading") }}...</h2>
      </div>
      <!-- Titolo del Modulo -->
      <div v-if="getSensorType !== 'vibrometer'" class="main-wrap over-info">
        <div style="display: flex;" class="d-flex align-items-center">
          <h2 v-if="getSensorType === 'tiltmeter'">{{ $t("graphTitlesDetails.tilt") }}</h2>
          <h2 v-if="getSensorType === 'deck'">{{ $t("graphTitlesDetails.deckTimeResponse") }}</h2>
          <h2 v-if="getSensorType === 'crackmeter'">{{ $t("dataType.extensions") }}</h2>
          <h2 v-if="getSensorType === 'accelerometer'">{{ $t("graphTitlesDetails.accTimeResponse") }}</h2>
          <h2 v-if="(getSensorType === 'analog-node' && getSelectedSensor && getSelectedSensor.rev === '1.0')">{{ $t("graphTitlesDetails.analogNode") }}</h2>
          <h2 v-if="(getSensorType === 'analog-node' && getSelectedSensor && getSelectedSensor.rev === '2.0')">{{ `${$t("nodeV2.dataTitle")} ${getShortEui(getSelectedSensor.eui)}` }} </h2>
          <div>
            <img v-if="getSensorType !== 'analog-node'" class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isInfoVisible = true" @mouseleave="isInfoVisible = false" />
            <AnglesBalloon :visible="getSensorType === 'tiltmeter' && isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
            <DeckBalloon :visible="getSensorType === 'deck' && isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
            <AccBalloon :visible="getSensorType === 'accelerometer' && isInfoVisible" style="position: absolute; margin-left: 20px" @close="isInfoVisible = false" />
            <move-ballon class="ml8" v-if="getSensorType === 'analog-node' && getSelectedSensor && getSelectedSensor.rev === '2.0'" width="400px">
              <template #content>
                <p>{{ $t('nodeV2.dataInfo') }}</p>
              </template>
            </move-ballon>
          </div>
        </div>
        <p v-if="getSensorType === 'tiltmeter'" class="first-block">{{ $t("graphSubTitlesDetails.tilt") }}</p>
        <p v-else-if="getSensorType === 'deck'" class="first-block">{{ $t("graphSubTitlesDetails.deckTimeResponse") }}</p>
        <p v-else-if="getSensorType === 'accelerometer'" class="first-block">{{ $t("graphSubTitlesDetails.accTimeResponse") }}</p>
        <p v-else-if="getSensorType === 'analog-node' && getSelectedSensor && getSelectedSensor.rev === '1.0'" class="first-block">{{ $t("graphSubTitlesDetails.analogNode") }}</p>
        <div v-else-if="getSensorType === 'analog-node' && getSelectedSensor && getSelectedSensor.rev === '2.0'" class="first-block mt16">
          <move-text p b> {{ getNodeV2ProbeName }} </move-text>
          <move-text p> {{ $t("nodeV2.dataSubtitle") }} </move-text>
        </div>
        <p v-else class="first-block">{{ $t("message.dataOfSelectedDevice") }}</p>
        <img v-if="getSensorType === 'deck' || getSensorType === 'accelerometer'" @click="downloadEventDetails" class="download-btn" src="../../../../public/assets/download.svg" />
      </div>
      <!-- Deck -->
      <LinePlot v-if="getDispDetails && getSensorType === 'deck'" :height="plotHeight" :traces="getDispTrace" yTitle="Displacement (mm)" xTitle="Time (s)" :xRange="[getDeckStartTime((getDispTrace[0].y || []).length), getDeckEndTime((getDispTrace[0].y || []).length)]" />
      <!-- Tiltmeter -->
      <div v-if="getTiltTraceSub1 && getSensorType === 'tiltmeter'">
        <LinePlot
          v-if="getSelectedSensor && getSensorType === 'tiltmeter'"
          :height="plotHeight"
          :traces="getTiltTraceSub1"
          xTitle="Time (Hr)"
          :yTitle="'Δφ ' + $t('dataType.tilt') + ' ' + (mesUnit && mesUnit.degrees === 'mm/m' ? '[mm/m]' : '°')"
        />
        <LinePlot
          v-if="getSelectedSensor && getSensorType === 'tiltmeter'"
          :height="plotHeight"
          :traces="getTiltTraceSub2"
          xTitle="Time (Hr)"
          :yTitle="'Δϑ ' + $t('dataType.tilt') + ' ' + (mesUnit && mesUnit.degrees === 'mm/m' ? '[mm/m]' : '°')"
        />
      </div>
      <!-- Crackmeter -->
      <LinePlot v-if="getCrackTrace && getSensorType === 'crackmeter' && !getSelectedSensor.subType" :height="plotHeight" :traces="getCrackTrace" :yTitle="$t('dataType.crack') + ' (mm)'" xTitle="Time (Date)" />
      <!-- Nodo rev 2 -->
      <div v-if="(getSelectedSensor && getSensorType === 'analog-node' && getSelectedSensor.rev === '2.0')">
        <!-- analog node data graph -->
        <div class="single-plot">
          <LinePlot
            :height="plotHeight"
            xTitle="Time (Hr)"
            :yTitle="getNodeV2MesUnit"
            :traces="getAnalogNodeV2Trace"
            showLegend
            :hoverlabel="{
              font:{
                color: 'white'
              }
            }"
            :legend="{
              orientation: 'h'
            }"
          />
        </div>
        <!-- NTC graph in raw mode -->
        <div class="single-plot" v-if="getNodeV2NtcEnable && getNodeV2Mode === 'raw'">
          <div class="main-wrap">
            <div class="d-flex align-items-center">
              <move-text p b class="text-align-left mr8"> {{ $t('nodeV2.internalNtcTitle') }} </move-text>
              <move-ballon width="400px">
                <template #content>
                  <p>{{ $t('nodeV2.ntcInfo') }}</p>
                </template>
              </move-ballon>
            </div>
            <move-text p> {{ $t('nodeV2.internalNtcSubtitle') }} </move-text>
          </div>
          <LinePlot
            :height="plotHeight"
            xTitle="Time (Hr)"
            :traces="getAnalogNodeV2RawTemperatureTrace"
            yTitle="Ω"
          />
        </div>
        <!-- NTC graph in custom mode -->
        <div class="single-plot" v-if="getNodeV2NtcEnable && getNodeV2Mode === 'custom'">
          <div class="main-wrap">
            <div class="d-flex align-items-center">
              <move-text p b class="text-align-left mr8"> {{ $t('nodeV2.temperatureTitle') }} </move-text>
              <move-ballon width="400px">
                <template #content>
                  <p>{{ $t('nodeV2.ntcInfo') }}</p>
                </template>
              </move-ballon>
            </div>
            <move-text p> {{ $t('nodeV2.temperatureSubtitle') }} </move-text>
          </div>
          <LinePlot
            :height="plotHeight"
            xTitle="Time (Hr)"
            :traces="getAnalogNodeV2ConvertedTemperatureTrace"
            yTitle="°C"
          />
        </div>
      </div>
      <!-- Nodo rev 1 -->
      <div v-else-if="getSelectedSensor && (getSensorType === 'analog-node' || getSensorType === 'digital-node')">
        <div>
          <div class="single-plot">
            <LinePlot
              v-if="getSelectedSensor"
              :height="plotHeight"
              :traces="getAnNodeCH1Trace"
              xTitle="Time (Hr)"
              :yTitle="getSelectedSensor.userConfig.channels[0].name + ' [' + (getSelectedSensor.userConfig.channels[0].mesUnit || ' - ') + ']'"
            />
          </div>
          <div class="single-plot">
            <LinePlot
              v-if="getSelectedSensor"
              :height="plotHeight"
              :traces="getAnNodeCH2Trace"
              xTitle="Time (Hr)"
              :yTitle="getSelectedSensor.userConfig.channels[1].name + ' [' + (getSelectedSensor.userConfig.channels[1].mesUnit || ' - ') + ']'"
            />
          </div>
          <div class="single-plot">
            <LinePlot
              v-if="getSelectedSensor"
              :height="plotHeight"
              :traces="getAnNodeCH3Trace"
              xTitle="Time (Hr)"
              :yTitle="getSelectedSensor.userConfig.channels[2].name + ' [' + (getSelectedSensor.userConfig.channels[2].mesUnit || ' - ') + ']'"
            />
          </div>
          <div class="single-plot">
            <LinePlot
              v-if="getSelectedSensor"
              :height="plotHeight"
              :traces="getAnNodeCH4Trace"
              xTitle="Time (Hr)"
              :yTitle="getSelectedSensor.userConfig.channels[3].name + ' [' + (getSelectedSensor.userConfig.channels[3].mesUnit || ' - ') + ']'"
            />
          </div>
        </div>
      </div>
      <!-- loadcell -->
      <LinePlot v-if="getCrackTrace && getSensorType === 'crackmeter' && getSelectedSensor.subType === 'loadcell'" :height="plotHeight" :traces="getLoadTrace" :yTitle="'Load (N)'" xTitle="Time (Date)" />

      <!-- Vibrometer -->
      <div v-if="getSelectedSensor && getSensorType === 'vibrometer'">
        <div class="main-wrap over-info" v-if="getSelectedSensor">
          <div style="display: flex;">
            <h2>{{ $t("graphTitlesDetails.PPV") }}</h2>
            <div>
              <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isPPVInfoVisible = true" @mouseleave="isPPVInfoVisible = false" />
              <PPVBalloon :visible="isPPVInfoVisible" style="position: absolute; margin-left: 20px" @close="isPPVInfoVisible = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("graphSubTitlesDetails.PPV") }}</p>
          <!-- Su quale range temporale prendo il massimo -->
          <p v-if="getPPVs && getPPVs.groupValue" class="first-block break-spaces">{{ $t("vibrometerPPVAggregationMessage", { range: getPPVs.groupValue }) }}</p>
        </div>
        <LinePlot v-if="getSelectedSensor && getSensorType === 'vibrometer'" :height="plotHeight" :traces="getPPVMagnitudeTrace" xTitle="Time (Hr)" :yTitle="`PPV [${getVelocityUnit}]`" />
        <div class="main-wrap over-info">
          <div style="display: flex;">
            <h2>{{ $t("graphTitlesDetails.PCPV") }}</h2>
            <div>
              <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isPCPVInfoVisible = true" @mouseleave="isPCPVInfoVisible = false" />
              <PCPVBalloon :visible="isPCPVInfoVisible" style="position: absolute; margin-left: 20px" @close="isPCPVInfoVisible = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("graphSubTitlesDetails.PCPV") }}</p>
          <!-- Su quale range temporale prendo il massimo -->
          <p v-if="getPPVs && getPPVs.groupValue" class="first-block break-spaces">{{ $t("vibrometerPCPVAggregationMessage", { range: getPPVs.groupValue }) }}</p>
        </div>
        <div class="multi-plot three-side-modules">
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVAmplitudesTraces[0]" xTitle="Time (Hr)" :yTitle="`PCPV X [${getVelocityUnit}]`" />
          </div>
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVAmplitudesTraces[1]" xTitle="Time (Hr)" :yTitle="`PCPV Y [${getVelocityUnit}]`" />
          </div>
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVAmplitudesTraces[2]" xTitle="Time (Hr)" :yTitle="`PCPV Z [${getVelocityUnit}]`" />
          </div>
        </div>
        <div class="main-wrap over-info">
          <div style="display: flex;">
            <h2>{{ $t("graphTitlesDetails.peakAmplitude") }}</h2>
            <div>
              <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isPeakAmpInfoVisbile = true" @mouseleave="isPeakAmpInfoVisbile = false" />
              <PeakAmpBalloon :visible="isPeakAmpInfoVisbile" style="position: absolute; margin-left: 20px" @close="isPeakAmpInfoVisbile = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("graphSubTitlesDetails.peakAmplitude") }}</p>
          <!-- Su quale range temporale prendo il massimo -->
          <p v-if="getPPVs && getPPVs.groupValue" class="first-block break-spaces">{{ $t("vibrometerAmpPeakAggregationMessage", { range: getPPVs.groupValue }) }}</p>
        </div>
        <div class="multi-plot three-side-modules">
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVFftAmplitudesTraces[0]" xTitle="Time (Hr)" :yTitle="`FFT Amplitude X [(${getVelocityUnit})/Hz]`" />
          </div>
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVFftAmplitudesTraces[1]" xTitle="Time (Hr)" :yTitle="`FFT Amplitude Y [(${getVelocityUnit})/Hz]`" />
          </div>
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVFftAmplitudesTraces[2]" xTitle="Time (Hr)" :yTitle="`FFT Amplitude Z [(${getVelocityUnit})/Hz]`" />
          </div>
        </div>
        <div class="main-wrap over-info">
          <div style="display: flex;">
            <h2>{{ $t("graphTitlesDetails.peakFrequency") }}</h2>
            <div>
              <img class="pointer" src="../../../../public/assets/icons/help.svg" alt="drag" @mouseover="isPeakFreqInfoVisible = true" @mouseleave="isPeakFreqInfoVisible = false" />
              <PeakFreqBalloon :visible="isPeakFreqInfoVisible" style="position: absolute; margin-left: 20px" @close="isPeakFreqInfoVisible = false" />
            </div>
          </div>
          <p class="first-block">{{ $t("graphSubTitlesDetails.peakFrequency") }}</p>
          <!-- Su quale range temporale prendo il massimo -->
          <p v-if="getPPVs && getPPVs.groupValue" class="first-block break-spaces">{{ $t("vibrometerFreqPeakAggregationMessage", { range: getPPVs.groupValue }) }}</p>
        </div>
        <div class="multi-plot three-side-modules">
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVFftFreqsTraces[0]" xTitle="Time (Hr)" yTitle="FFT Freq X [Hz]" />
          </div>
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVFftFreqsTraces[1]" xTitle="Time (Hr)" yTitle="FFT Freq Y [Hz]" />
          </div>
          <div class="single-plot">
            <LinePlot v-if="getSelectedSensor" :height="plotHeight" :traces="getPPVFftFreqsTraces[2]" xTitle="Time (Hr)" yTitle="FFT Freq Z [Hz]" />
          </div>
        </div>
      </div>

      <!-- Accelerometer (3 Plots)-->
      <!-- type === trigger_pkpk -->
      <div v-if="getDispDetails && getSensorType === 'accelerometer' && getAccelerationType === 'trigger_pkpk'">
        <div class="resume-table">
          <table id="customers">
            <tr>
              <th><h2></h2></th>
              <th><h2>Peak (mg)</h2></th>
              <th><h2>Max peak (mg)</h2></th>
              <th><h2>Min peak (mg)</h2></th>
            </tr>
            <tr>
              <td><p>X</p></td>
              <td><p>{{getAccelerationsPeak.x.toFixed(4)}}</p></td>
              <td><p>{{getMaxAccelerationsPeak.x.toFixed(4)}}</p></td>
              <td><p>{{getMinAccelerationsPeak.x.toFixed(4)}}</p></td>
            </tr>
            <tr>
              <td><p>Y</p></td>
              <td><p>{{getAccelerationsPeak.y.toFixed(4)}}</p></td>
              <td><p>{{getMaxAccelerationsPeak.y.toFixed(4)}}</p></td>
              <td><p>{{getMinAccelerationsPeak.y.toFixed(4)}}</p></td>
            </tr>
            <tr>
              <td><p>Z</p></td>
              <td><p>{{getAccelerationsPeak.z.toFixed(4)}}</p></td>
              <td><p>{{getMaxAccelerationsPeak.z.toFixed(4)}}</p></td>
              <td><p>{{getMinAccelerationsPeak.z.toFixed(4)}}</p></td>
            </tr>
          </table>
        </div>
        <div class="acc-plot-wrapper">
          <LinePlot :height="plotHeight" :traces="getAccRmsXTrace" yTitle="X RMS (mg)" xTitle="Time (s)"/>
        </div>
        <div class="acc-plot-wrapper">
          <LinePlot :height="plotHeight" :traces="getAccRmsYTrace" yTitle="Y RMS (mg)" xTitle="Time (s)"/>
        </div>
        <div class="acc-plot-wrapper">
          <LinePlot :height="plotHeight" :traces="getAccRmsZTrace" yTitle="Z RMS (mg)" xTitle="Time (s)"/>
        </div>
      </div>  
      <!-- type !== trigger_pkpk -->
      <div v-else-if="getDispDetails && getSensorType === 'accelerometer'">
        <div class="acc-plot-wrapper">
          <LinePlot :height="plotHeight" :traces="getAccXTrace" yTitle="X (mg)" xTitle="Time (s)" :yRange="[Math.min(...getAccXTrace[0].y) > -1 ? -1 : null, Math.max(...getAccXTrace[0].y) < 1 ? 1 : null]" />
        </div>
        <div class="acc-plot-wrapper">
          <LinePlot :height="plotHeight" :traces="getAccYTrace" yTitle="Y (mg)" xTitle="Time (s)" :yRange="[Math.min(...getAccYTrace[0].y) > -1 ? -1 : null, Math.max(...getAccYTrace[0].y) < 1 ? 1 : null]" />
        </div>
        <div class="acc-plot-wrapper">
          <LinePlot :height="plotHeight" :traces="getAccZTrace" yTitle="Z (mg)" xTitle="Time (s)" :yRange="[Math.min(...getAccZTrace[0].y) > -1 ? -1 : null, Math.max(...getAccZTrace[0].y) < 1 ? 1 : null]" />
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import LinePlot from "../../graphs/LinePlot.vue";
import { mapGetters } from "vuex";
import AnglesBalloon from "../../tutorial/balloons/overview/PhiThetaBl.vue";
import DeckBalloon from "../../tutorial/balloons/details/TimeRespDeckBl.vue";
import AccBalloon from "../../tutorial/balloons/details/TimeRespAccBl.vue";
import PPVBalloon from "../../tutorial/balloons/details/PPVVibBl.vue";
import PCPVBalloon from "../../tutorial/balloons/details/PCPVVibBl.vue";
import PeakAmpBalloon from "../../tutorial/balloons/details/PeakAmpVibBl.vue";
import PeakFreqBalloon from "../../tutorial/balloons/details/PeakFreqVibBl.vue";
import { useTimestampFieldBySensor } from "../../../helpers/sensors";
import { exportCSVFile } from "../../../helpers/download.js";
import { getShortEui } from "../../../helpers/sensors";
import math from "../../../helpers/math.js";
import EnergyConsumption from "../../modules_V2/EnergyConsumption.vue";
var mql = window.matchMedia("screen and (max-width: 1499px)");


export default {
  name: "event-module",
  components: {
    LinePlot,
    AnglesBalloon,
    DeckBalloon,
    AccBalloon,
    PPVBalloon,
    PCPVBalloon,
    PeakAmpBalloon,
    PeakFreqBalloon,
    EnergyConsumption
},
  data() {
    return {
      modalEnergyConsumptionDetails: false,
      xRange: null,
      isInfoVisible: false,
      // Balloon PPV
      isPPVInfoVisible: false,
      isPCPVInfoVisible: false,
      isPeakAmpInfoVisbile: false,
      isPeakFreqInfoVisible: false,
      // Fine Balloon PPV
      plotHeight: 490,
      baseTrace: {
        connectgaps: true,
        /* Trace di base a cui vengono aggiunti attributi in base al grafico */
        line: {
          color: "rgb(17, 205, 239)",
          width: 2,
        },
        marker: {
          color: "rgb(255, 255, 255)",
          size: 8,
        },
        name: "Peaks Line",
        type: "scatter",
        xaxis: "x",
        yaxis: "y",
        opacity: 0.9,
        textfont: {},
      },
    };
  },
  methods: {
    getShortEui: getShortEui,
    // Restituisce il sample rate in (Hz) di un evento data la lungheza del suo payload
    getDeckSampleRate(payloadLength) {
      return payloadLength > 1600 ? 100 : 50;
    },
    // Restituisce il time di inizio di un evento di Deck sull'asse dei tempi
    getDeckStartTime(payloadLength) {
      const sr = this.getDeckSampleRate(payloadLength); // 50 | 100
      return -(payloadLength / sr - (payloadLength === 400 ? 6 : 20)) || 0;
    },
    // Restituisce il time di fine di un evento di Deck sull'asse dei tempi
    getDeckEndTime(payloadLength) {
      const sr = this.getDeckSampleRate(payloadLength); // 50 | 100
      return this.getDeckStartTime(payloadLength) + payloadLength / sr || 0;
    },
    responsiveScreenHandler: function(e) {
      // Modifico l'attributo 'height' del grafico in base alla dimensione della finestra, vedere 'mql'
      if (e.matches) {
        this.plotHeight = 350;
      } else {
        this.plotHeight = 490;
      }
    },
    downloadEventDetails: function() {
      if (this.getIsThereData && this.getLoadingStatus) {
        // Ritorna header ed eventi da passare alla funzione che crea il .CSV
        let events = [];
        let headers = {};
        let title;
        let devEui = this.getSelectedSensor.eui;
        let smallestArray;
        switch (this.getSensorType) {
          case "deck":
            events = this.getDispDetails.payload.dati.map(function(item) {
              return {
                eui: devEui.substr(devEui.length - 5),
                amplitude: item,
              };
            });
            headers = {
              eui: "Device",
              amplitude: "Amplitude",
            };
            title = "Event Deck " + devEui.substr(devEui.length - 5);
            break;
          case "accelerometer":
            if (this.getAccelerationType === 'trigger_pkpk') {
              const eui =  devEui.substr(devEui.length - 5);
              title = "Event Accelerometer " + eui;
              headers = {
                eui: "Device",
                dateUtc: "Date(UTC)",
                timestamp: "Timestamp",
                resolution_g: "Resolution (g)",
                pkpkXacc_g: "Pk-Pk X Acc (g)",
                pkpkYacc_g: "Pk-Pk Y Acc (g)",
                pkpkZacc_g: "Pk-Pk Z Acc (g)",
                maxXpeak_g: "Max X Peak Acc (g)",
                maxYpeak_g: "Max Y Peak Acc (g)",
                maxZpeak_g: "Max Z Peak Acc (g)",
                minXpeak_g: "Min X Peak Acc (g)",
                minYpeak_g: "Min Y Peak Acc (g)",
                minZpeak_g: "Min Z Peak Acc (g)",
                rmsX_0: "rmsX[0]",
                rmsY_0: "rmsY[0]",
                rmsZ_0: "rmsZ[0]",
                rmsX_1: "rmsX[1]",
                rmsY_1: "rmsY[1]",
                rmsZ_1: "rmsZ[1]",
                rmsX_2: "rmsX[2]",
                rmsY_2: "rmsY[2]",
                rmsZ_2: "rmsZ[2]",
                rmsX_3: "rmsX[3]",
                rmsY_3: "rmsY[3]",
                rmsZ_3: "rmsZ[3]",
                sample: "sample"
              };
              const row = {
                eui: eui || null,
                dateUtc: this.getAccelerationDetails.date || null,
                timestamp: this.getAccelerationDetails.timestamp || null,
                resolution_g: (this.getAccelerationDetails.resolution / 1000) || null,
                pkpkXacc_g: this.getAccelerationDetails.acceleration.xPeak || null,
                pkpkYacc_g: this.getAccelerationDetails.acceleration.yPeak || null,
                pkpkZacc_g: this.getAccelerationDetails.acceleration.zPeak || null,
                maxXpeak_g: this.getAccelerationDetails.acceleration.maxXPeak || null,
                maxYpeak_g: this.getAccelerationDetails.acceleration.maxYPeak || null,
                maxZpeak_g: this.getAccelerationDetails.acceleration.maxZPeak || null,
                minXpeak_g: this.getAccelerationDetails.acceleration.minXPeak || null,
                minYpeak_g: this.getAccelerationDetails.acceleration.minYPeak || null,
                minZpeak_g: this.getAccelerationDetails.acceleration.minZPeak || null,
                rmsX_0: this.getAccelerationDetails.acceleration.rmsX[0] || null,
                rmsY_0: this.getAccelerationDetails.acceleration.rmsY[0] || null,
                rmsZ_0: this.getAccelerationDetails.acceleration.rmsZ[0] || null,
                rmsX_1: this.getAccelerationDetails.acceleration.rmsX[1] || null,
                rmsY_1: this.getAccelerationDetails.acceleration.rmsY[1] || null,
                rmsZ_1: this.getAccelerationDetails.acceleration.rmsZ[1] || null,
                rmsX_2: this.getAccelerationDetails.acceleration.rmsX[2] || null,
                rmsY_2: this.getAccelerationDetails.acceleration.rmsY[2] || null,
                rmsZ_2: this.getAccelerationDetails.acceleration.rmsZ[2] || null,
                rmsX_3: this.getAccelerationDetails.acceleration.rmsX[3] || null,
                rmsY_3: this.getAccelerationDetails.acceleration.rmsY[3] || null,
                rmsZ_3: this.getAccelerationDetails.acceleration.rmsZ[3] || null,
                sample: this.getAccelerationDetails.fSample || null
              }
              events.push(row);
            }
            else{
              smallestArray = Math.min(this.getAccelerationDetails.acceleration.x.length, this.getAccelerationDetails.acceleration.y.length, this.getAccelerationDetails.acceleration.z.length);
              for (let i = 0; i < smallestArray; i++) {
                events.push({
                  eui: devEui.substr(devEui.length - 5),
                  xAmplitude: this.getAccelerationDetails.acceleration.x[i],
                  yAmplitude: this.getAccelerationDetails.acceleration.y[i],
                  zAmplitude: this.getAccelerationDetails.acceleration.z[i],
                });
              }
              headers = {
                eui: "Device",
                accX: "X Acc (g)",
                accY: "Y Acc (g)",
                accZ: "Z Acc (g)",
              };
              title = "Event Accelerometer " + devEui.substr(devEui.length - 5);
            }
            break;
        }
        exportCSVFile(
          // Scarica Eventi del device (no temperature)
          headers,
          events,
          title
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      getSensorType: "eventdetails/getSensorType",
      getDispDetails: "eventdetails/getDisplacementDetails",
      getSelectedSensor: "eventdetails/getSelectedSensor",
      loading: "eventdetails/getLoadingStatus",
      getCracks: "eventdetails/getCracks",
      getAnalogReadings: "eventdetails/getAnalogReadings",
      getAccelerationDetails: "eventdetails/getAccelerationDetails",
      getTilts: "eventdetails/getTilts",
      getPPVs: "eventdetails/getPPVs",
      getBaseValue: "eventdetails/getBaseValue",
      getRelativeMode: "eventdetails/getRelativeMode",
      getLoadingStatus: "eventdetails/getLoadingStatus",
      getIsThereData: "eventdetails/getIsThereData",
      getUserInterface: "user/getInterface",
    }),
    getNovdeV2Channel0() {
      return (
        this.getSelectedSensor &&
        this.getSelectedSensor.userConfig &&
        this.getSelectedSensor.userConfig.channels &&
        this.getSelectedSensor.userConfig.channels.length &&
        this.getSelectedSensor.userConfig.channels[0]
      );
    },
    getNodeV2MesUnit() {
      return this.getNovdeV2Channel0 && this.getNovdeV2Channel0.mesUnit;
    },
    getNodeV2ProbeName() {
      return this.getNovdeV2Channel0 && this.getNovdeV2Channel0.name;
    },
    getNodeV2NtcEnable() {
      return (
        this.getNovdeV2Channel0 &&
        this.getNovdeV2Channel0.externalNtc &&
        this.getNovdeV2Channel0.externalNtc.enabled
      );
    },
    getNodeV2Mode(){
      return (
        this.getNovdeV2Channel0 &&
        this.getNovdeV2Channel0.mode
      );
    },
    mesUnit() {
      return this.getUserInterface ? this.getUserInterface.dimensions : null;
    },
    getVelocityUnit(){
      return (this.mesUnit || {}).velocity || 'mm/s'
    },
    /* Restituisce un oggetto contente le PPVs */
    getAxisPPVs: function() {
      return {
        dates: this.getPPVs && this.getPPVs.values.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date)),
        magnitudes: this.getPPVs && this.getPPVs.values.map((event) => event.ppv),
        xAmp: this.getPPVs && this.getPPVs.values.map((event) => event.amplitudes[0]),
        yAmp: this.getPPVs && this.getPPVs.values.map((event) => event.amplitudes[1]),
        zAmp: this.getPPVs && this.getPPVs.values.map((event) => event.amplitudes[2]),
        xFftAmp: this.getPPVs && this.getPPVs.values.map((event) => event.fftAmplitudes[0]),
        yFftAmp: this.getPPVs && this.getPPVs.values.map((event) => event.fftAmplitudes[1]),
        zFftAmp: this.getPPVs && this.getPPVs.values.map((event) => event.fftAmplitudes[2]),
        xFreq: this.getPPVs && this.getPPVs.values.map((event) => event.fftFreqs[0]),
        yFreq: this.getPPVs && this.getPPVs.values.map((event) => event.fftFreqs[1]),
        zFreq: this.getPPVs && this.getPPVs.values.map((event) => event.fftFreqs[2]),
      };
    },
    getPPVMagnitudeTrace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAxisPPVs.dates;
      basicTrace.y = this.getPPVs && this.getPPVs.values.map((event) => event.ppv);
      return [basicTrace];
    },
    getPPVAmplitudesTraces: function() {
      let basicTraceX = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceY = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceZ = JSON.parse(JSON.stringify(this.baseTrace));
      basicTraceX.x = this.getAxisPPVs.dates;
      basicTraceX.y = this.getAxisPPVs.xAmp;
      basicTraceY.x = this.getAxisPPVs.dates;
      basicTraceY.y = this.getAxisPPVs.yAmp;
      basicTraceZ.x = this.getAxisPPVs.dates;
      basicTraceZ.y = this.getAxisPPVs.zAmp;
      return [[basicTraceX], [basicTraceY], [basicTraceZ]];
    },
    getPPVFftAmplitudesTraces: function() {
      let basicTraceX = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceY = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceZ = JSON.parse(JSON.stringify(this.baseTrace));
      basicTraceX.x = this.getAxisPPVs.dates;
      basicTraceX.y = this.getAxisPPVs.xFftAmp;
      basicTraceY.x = this.getAxisPPVs.dates;
      basicTraceY.y = this.getAxisPPVs.yFftAmp;
      basicTraceZ.x = this.getAxisPPVs.dates;
      basicTraceZ.y = this.getAxisPPVs.zFftAmp;
      return [[basicTraceX], [basicTraceY], [basicTraceZ]];
    },
    getPPVFftFreqsTraces: function() {
      let basicTraceX = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceY = JSON.parse(JSON.stringify(this.baseTrace));
      let basicTraceZ = JSON.parse(JSON.stringify(this.baseTrace));
      basicTraceX.x = this.getAxisPPVs.dates;
      basicTraceX.y = this.getAxisPPVs.xFreq;
      basicTraceY.x = this.getAxisPPVs.dates;
      basicTraceY.y = this.getAxisPPVs.yFreq;
      basicTraceZ.x = this.getAxisPPVs.dates;
      basicTraceZ.y = this.getAxisPPVs.zFreq;
      return [[basicTraceX], [basicTraceY], [basicTraceZ]];
    },
    getAnalogNodeV2Trace(){
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));

      //TRACE Scheduled +  triggered
      basicTrace.name = 'Scheduled + triggered'
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));

      basicTrace.y = this.getAnalogReadings.map((event) => event.value === undefined ? null : event.value);
      basicTrace.mode = 'lines'

      basicTrace.hovertemplate = 
        "Value: %{y} %{yaxis.title.text}<br>" +
        "Date: %{x}<br><br>" +
        "<extra></extra>"

      //ONLY Scheduled trace
      let scheduledTrace = JSON.parse(JSON.stringify(this.baseTrace));
      scheduledTrace.line.color = 'green'
      scheduledTrace.name = 'Scheduled trend'
      scheduledTrace.mode = 'lines'
      scheduledTrace.visible = 'legendonly'

      scheduledTrace.x = this.getAnalogReadings
        .filter((event) => event.type === 'scheduled')
        .map((event) => new Date(event.timestamp || event.date));
      scheduledTrace.y = this.getAnalogReadings
        .filter((event) => event.type === 'scheduled')
        .map((event) => event.value === undefined ? null : event.value)
      
      scheduledTrace.hovertemplate = 
        "Value: %{y} %{yaxis.title.text}<br>" +
        "Date: %{x}<br><br>" +
        "<extra></extra>"

      //ONLY Triggered trace
      let triggeredTrace = JSON.parse(JSON.stringify(this.baseTrace));

      triggeredTrace.marker.size = 3
      triggeredTrace.marker.color = 'ff5050'
      triggeredTrace.name = 'Triggered markers'
      triggeredTrace.mode = 'markers';

      triggeredTrace.customdata = this.getAnalogReadings.filter((event) => event.type === 'triggered_acc').map(event => {
        const peakValue = event.acceleration.peakValue === undefined ? null : event.acceleration.peakValue;
        const rmsValue = event.acceleration.rmsValue === undefined ? null : event.acceleration.rmsValue;
        const peakAxis = event.acceleration.peakAxis === undefined ? null : event.acceleration.peakAxis;
        const rmsAxis = event.acceleration.rmsAxis === undefined ? null : event.acceleration.rmsAxis;
        return `<i>peakValue</i>: ${peakValue}<br>` +
              `<i>rmsValue</i>: ${rmsValue}<br>` +
              `<i>peakAxis</i>: ${peakAxis}<br>` +
              `<i>rmsAxis</i>: ${rmsAxis}<br>`
      })

      triggeredTrace.hovertemplate = 
            "Value: %{y} %{yaxis.title.text}<br>" +
            "Date: %{x}<br><br>" +
            "<b>Acceleration</b><br>" +
            "%{customdata}" +
            "<extra></extra>"

      triggeredTrace.x = this.getAnalogReadings
        .filter((event) => event.type === 'triggered_acc')
        .map((event) => new Date(event.timestamp || event.date));
      triggeredTrace.y = this.getAnalogReadings
        .filter((event) => event.type === 'triggered_acc')
        .map((event) => event.value === undefined ? null : event.value);
      return [basicTrace, triggeredTrace, scheduledTrace ];

    },
    getAnalogNodeV2ConvertedTemperatureTrace(){
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.convertedTemperature === undefined ? null : event.convertedTemperature);
      return [basicTrace];
    },
    getAnalogNodeV2RawTemperatureTrace(){
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.probeTemperature === undefined ? null : event.probeTemperature);
      return [basicTrace];
    },
    getAnNodeCH1Trace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.channelsData && event.channelsData.length && event.channelsData[0]);
      return [basicTrace];
    },
    getAnNodeCH2Trace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.channelsData && event.channelsData.length && event.channelsData[1]);
      return [basicTrace];
    },
    getAnNodeCH3Trace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.channelsData && event.channelsData.length && event.channelsData[2]);
      return [basicTrace];
    },
    getAnNodeCH4Trace: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.x = this.getAnalogReadings.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
      basicTrace.y = this.getAnalogReadings.map((event) => event.channelsData && event.channelsData.length && event.channelsData[3]);
      return [basicTrace];
    },
    getTiltTrace: function() {
      // Ritorna la trace relativa all'Inclinometro
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getTilts.length > 0) {
        basicTrace.x = this.getTilts.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getTilts.map((event) => (this.mesUnit && this.mesUnit.degrees === "mm/m" ? math.degreesToMM(event.extraAngle).toFixed(6) : event.extraAngle.toFixed(6)));
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getTiltTraceSub1: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getTilts) {
        basicTrace.x = this.getTilts.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getTilts.map((event) => (this.mesUnit && this.mesUnit.degrees === "mm/m" ? math.degreesToMM(event.phiAngle).toFixed(6) : event.phiAngle.toFixed(6)));
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getTiltTraceSub2: function() {
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getTilts) {
        basicTrace.x = this.getTilts.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getTilts.map((event) => (this.mesUnit && this.mesUnit.degrees === "mm/m" ? math.degreesToMM(event.thetaAngle).toFixed(6) : event.thetaAngle.toFixed(6)));
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getCrackTrace: function() {
      // Ritorna la trace relativa all'Estensimetro
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      let offset = this.getRelativeMode && this.getBaseValue ? this.getBaseValue : 0;
      if (this.getCracks.length > 0) {
        basicTrace.x = this.getCracks.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getCracks.map((event) => event.sample - offset);
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getLoadTrace: function() {
      // Ritorna la trace relativa all'Estensimetro
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getCracks.length > 0) {
        basicTrace.x = this.getCracks.map((event) => new Date((useTimestampFieldBySensor(this.getSelectedSensor) && event.timestamp) || event.date));
        basicTrace.y = this.getCracks.map((event) => (event.sample / (3.92 * Math.pow(10, -3)) - 6400) * 11.71875);
      } else {
        basicTrace.x = [];
        basicTrace.y = [];
      }
      return [basicTrace];
    },
    getDispTrace: function() {
      // Ritorna la trace relativa all Deck
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      if (this.getDispDetails.payload) {
        let sampleRate = this.getDeckSampleRate(this.getDispDetails.payload.dati.length);
        if (this.getSensorType === "deck") {
          let begin = this.getDeckStartTime(this.getDispDetails.payload.dati ? this.getDispDetails.payload.dati.length : 0);
          basicTrace.y = this.getDispDetails.payload.dati;
          basicTrace.x = this.getDispDetails.payload.dati.map((val, ind) => ind / sampleRate + begin);
        }
      }
      return [basicTrace];
    },
    getAccelerationType(){
      return this.getAccelerationDetails && this.getAccelerationDetails.type ? this.getAccelerationDetails.type : null
    },
    getMaxAccelerationsPeak: function(){
      return {
        x: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.maxXPeak ? this.getAccelerationDetails.acceleration.maxXPeak * 1000 : 0,
        y: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.maxYPeak ? this.getAccelerationDetails.acceleration.maxYPeak * 1000 : 0,
        z: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.maxZPeak ? this.getAccelerationDetails.acceleration.maxZPeak * 1000 : 0,
      }
    },
    getMinAccelerationsPeak: function(){
      return {
        x: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.minXPeak ? this.getAccelerationDetails.acceleration.minXPeak * 1000 : 0,
        y: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.minYPeak ? this.getAccelerationDetails.acceleration.minYPeak * 1000 : 0,
        z: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.minZPeak ? this.getAccelerationDetails.acceleration.minZPeak * 1000 : 0,
      }
    },
    getAccelerationsPeak: function(){
      return {
        x: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.xPeak ? this.getAccelerationDetails.acceleration.xPeak * 1000 : 0,
        y: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.yPeak ? this.getAccelerationDetails.acceleration.yPeak * 1000 : 0,
        z: this.getAccelerationDetails.acceleration && this.getAccelerationDetails.acceleration.zPeak ? this.getAccelerationDetails.acceleration.zPeak * 1000 : 0,
      }
    },
    getAxixAccelerations: function() {
      // Restituisce un oggetto contente 4 array relativi alle date ed alle accelerazioni rispettive sui 3 assi
      return {
        x: this.getAccelerationDetails.acceleration ? this.getAccelerationDetails.acceleration.x.map((a) => a * 1000) : [],
        y: this.getAccelerationDetails.acceleration ? this.getAccelerationDetails.acceleration.y.map((a) => a * 1000) : [],
        z: this.getAccelerationDetails.acceleration ? this.getAccelerationDetails.acceleration.z.map((a) => a * 1000) : [],
        duration: this.getAccelerationDetails.duration,
      };
    },
    getAxixRmsAccelerations: function() {
      // Restituisce un oggetto contente 4 array relativi alle date ed alle accelerazioni rispettive sui 3 assi
      return {
        x: this.getAccelerationDetails.acceleration ? this.getAccelerationDetails.acceleration.rmsX.map((a) => a * 1000) : [],
        y: this.getAccelerationDetails.acceleration ? this.getAccelerationDetails.acceleration.rmsY.map((a) => a * 1000) : [],
        z: this.getAccelerationDetails.acceleration ? this.getAccelerationDetails.acceleration.rmsZ.map((a) => a * 1000) : [],
        duration: this.getAccelerationDetails.duration,
      };
    },
    getAccXTrace: function() {
      // Restituisce la trace relativa alle accelerazione sull'asse X
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.y = this.getAxixAccelerations.x || [];
      basicTrace.x = this.getAxixAccelerations.x.map((v, i) => {
        const defaultValue = (i * 20) / 1000;
        const dx = this.getAxixAccelerations.x.length > 1 ? (i / (this.getAxixAccelerations.x.length - 1)) : 0;
        return this.getAxixAccelerations.duration ? (this.getAxixAccelerations.duration * dx ): defaultValue
      });
      return [basicTrace];
    },
    getAccYTrace: function() {
      // Restituisce la trace relativa alle accelerazione sull'asse Y
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.y = this.getAxixAccelerations.y || [];
      basicTrace.x = this.getAxixAccelerations.y.map((v, i) => {
        const defaultValue = (i * 20) / 1000;
        const dx = this.getAxixAccelerations.y.length > 1 ? (i / (this.getAxixAccelerations.y.length - 1)) : 0;
        return this.getAxixAccelerations.duration ? (this.getAxixAccelerations.duration * dx ): defaultValue
      });
      return [basicTrace];
    },
    getAccZTrace: function() {
      // Restituisce la trace relativa alle accelerazione sull'asse Z
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.y = this.getAxixAccelerations.z || [];
      basicTrace.x = this.getAxixAccelerations.z.map((v, i) => {
        const defaultValue = (i * 20) / 1000;
        const dx = this.getAxixAccelerations.z.length > 1 ? (i / (this.getAxixAccelerations.z.length - 1)) : 0;
        return this.getAxixAccelerations.duration ? (this.getAxixAccelerations.duration * dx ): defaultValue
      });
      return [basicTrace];
    },
   
    getAccRmsXTrace: function() {
      // Restituisce la trace relativa alle accelerazione sull'asse X
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.y = this.getAxixRmsAccelerations.x || [];
      basicTrace.x = this.getAxixRmsAccelerations.x.map((v, i) => {
        const defaultValue = (i * 20) / 1000;
        const dx = this.getAxixRmsAccelerations.x.length > 1 ? (i / (this.getAxixRmsAccelerations.x.length - 1)) : 0;
        return this.getAxixRmsAccelerations.duration ? (this.getAxixRmsAccelerations.duration * dx ): defaultValue
      });
      console.log("getAccRmsXTrace > basicTrace", basicTrace);
      return [basicTrace];
    },
    getAccRmsYTrace: function() {
      // Restituisce la trace relativa alle accelerazione sull'asse Y
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.y = this.getAxixRmsAccelerations.y || [];
      basicTrace.x = this.getAxixRmsAccelerations.y.map((v, i) => {
        const defaultValue = (i * 20) / 1000;
        const dx = this.getAxixRmsAccelerations.y.length > 1 ? (i / (this.getAxixRmsAccelerations.y.length - 1)) : 0;
        return this.getAxixRmsAccelerations.duration ? (this.getAxixRmsAccelerations.duration * dx ): defaultValue
      });
      return [basicTrace];
    },
    getAccRmsZTrace: function() {
      // Restituisce la trace relativa alle accelerazione sull'asse Z
      let basicTrace = JSON.parse(JSON.stringify(this.baseTrace));
      basicTrace.y = this.getAxixRmsAccelerations.z || [];
      basicTrace.x = this.getAxixRmsAccelerations.z.map((v, i) => {
        const defaultValue = (i * 20) / 1000;
        const dx = this.getAxixRmsAccelerations.z.length > 1 ? (i / (this.getAxixRmsAccelerations.z.length - 1)) : 0;
        return this.getAxixRmsAccelerations.duration ? (this.getAxixRmsAccelerations.duration * dx ): defaultValue
      });
      return [basicTrace];
    },
  },
  async mounted() {
    // Verifico la dimensione della finestra e aggiungo listener per gestire il ridimensionamento
    this.responsiveScreenHandler(mql);
    mql.addEventListener("change", () => {
      this.responsiveScreenHandler;
    });
  },
};
</script>
<style scoped>
h2 {
  font-size: 0.9em;
}
/* Module Wrapper */
.over {
  width: 100%;
  min-width: 600px;
  column-gap: 2%;
  display: grid;
  grid-template-columns: 49% 49%;
}
/* Divisori */
.horiz-divider {
  height: 2px;
  background-color: rgba(80, 84, 105, 1);
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 90%;
}

/* Inner Wrapper */
.details-wrapper {
  position: relative;
  margin-top: 30px;
  height: fit-content;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}
.double-small-graphs {
  display: grid;
  grid-template-columns: 5fr 5fr;
  column-gap: 40px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.large {
  height: auto;
}

.download-btn {
  cursor: pointer;
  grid-row: 1/3;
  height: 18px;
  justify-self: end;
  margin-right: 32px;
  background-color: rgb(33, 150, 243);
  padding: 10px;
  grid-column: 2;
  border-radius: 10px;
}

.acc-plot-wrapper {
  min-height: 350px;
}
/* Tra 600 e 1499 */
@media all and (max-width: 1499px) and (min-width: 300px) {
  .large {
    height: auto;
    min-height: 1120px;
  }
}

#temps {
  width: 100%;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}

#tx-trend {
  grid-row: 1;
  grid-column: 1;
}

#temp-trend {
  grid-row: 1;
  grid-column: 2;
}

.main-wrap {
  margin-top: 20px;
  display: grid;
  margin-left: 60px;
  grid-template-columns: max-content;
}
.over-info > h2 {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
}
.over-info > p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 60px;
}
.over-info {
  grid-template-columns: 10fr min-content;
  text-align: left;
  position: relative;
}
.three-side-modules {
  display: grid;
  grid-template-columns: 3.3fr 3.3fr 3.3fr;
}
.pointer {
  margin-left: 6px;
}



/* table */

.resume-table{
  margin: 40px;
}
#customers {
  border-collapse: collapse;
  width: 100%;

}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}



#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  color: white;
}

.modal-size{
  height: 90vh;
  width: 90vw;
}
</style>
