<i18n>
{
    "en":{
      "title":"Energy consumption details",
      "info":{
         "message":"Average consumption indicator for the probe connected to the node, referred to the Date Range selected. It is calculated taking into account:",
         "li-1":"Daily number of acquisitions",
         "li-2":"Current absorbed during acquisitions",
         "li-3":"Duration of the acquisitions"
      },
      "subtitle": "Energy consumption estimation over the selected time period",
      "indicatorLevels": {
        "low": "Low",
        "medium": "Medium",
        "high": "High"
      },
      "btnShowDetails": "View details"
    },
    "it":{
      "title":"Consumo energetico",
      "info":{
         "message":"Indicatore di consumo medio della sonda connessa al nodo, nel periodo di tempo selezionato. Viene calcolato tenendo conto di:",
         "li-1":"Numero di acquisizioni giornaliero",
         "li-2":"Corrente assorbita durante le acquisizioni",
         "li-3":"Durata delle acquisizioni"
      },
      "subtitle": "Stima del consumo medio sul periodo di tempo selezionato",
      "indicatorLevels": {
        "low": "Basso",
        "medium": "Medio",
        "high": "Alto"
      },
      "btnShowDetails": "Vedi dettagli"
    }
}
</i18n>

<template>
  <move-card rounded outlined class="text-align-left m32">
    <div class="mb32 d-flex justify-content-space-between">
      <div>
        <div class="d-flex align-items-center mb4">
          <move-text p h3 > {{ $t("title") }} </move-text>
          <move-ballon class="ml8" width="600px">
            <template #content>
              <p>{{ $t('info.message') }}</p>
              <ul>
                <li>{{ $t("info.li-1") }}</li>
                <li>{{ $t("info.li-2") }}</li>
                <li>{{ $t("info.li-3") }}</li>
              </ul>
            </template>
          </move-ballon>
        </div>
        <move-text p hint secondary> {{ $t('subtitle') }}</move-text>
      </div>
      <div>
        <move-btn
          outlined
          primary
          rounded
          @click="$emit('click-details')"
        >
          <move-text p>{{ $t('btnShowDetails') }}</move-text>
        </move-btn>
      </div>
    </div>

    <move-heat-bar :value="getEnergyConsumptionNodeV2.batteryConsumption">
      <template #indicator>
        <font-awesome-icon
          icon="fa-solid fa-location-pin"
          size="xl"
          color="white"
        />
      </template>
      <div class="d-flex justify-content-space-between">
        <move-text p>{{ $t('indicatorLevels.low') }}</move-text>
        <move-text p>{{ $t('indicatorLevels.medium') }}</move-text>
        <move-text p>{{ $t('indicatorLevels.high') }}</move-text>
      </div>
    </move-heat-bar>
  </move-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getEnergyConsumption } from "../../helpers/energyConsumption"
export default {
  name: 'EnergyConsumptionCard',
  data(){
    return{
      indicator: 0
    }
  },
  computed: {
    ...mapGetters({
      getSelectedSensor: "eventdetails/getSelectedSensor",
      getDateRange: "eventdetails/getDateRange",
      getAnalogReadings: "eventdetails/getAnalogReadings",
    }),
    getEnergyConsumptionNodeV2(){
      return getEnergyConsumption(this.getSelectedSensor, {eventList: this.getAnalogReadings, dateRange: this.getDateRange})
    }
  }
};
</script>
