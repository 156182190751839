<template>
  <div class="event-list">
    <div class="heading">
      <h2>{{ $t("title.eventList") }}</h2>
      <p>{{ $t("subTitle.eventList") }}</p>
    </div>
    <div class="legend">
      <p class="c1 r1">{{ $t("message.date") }}</p>
      <img class="c1 r1 jend" src="../../../public/assets/order.svg" @click="sortByDate()" />
      <!-- <p>Ora</p> -->
      <p class="c3 r1">Pk to Pk</p>
      <img class="c3 r1 jend" src="../../../public/assets/order.svg" @click="sortByPk()" />
    </div>
    <div class="event-box" id="events">
      <div class="buttons-wrapper">
        <ul>
          <li
            v-for="event in computedEvents"
            v-bind:key="event._id"
            @click="selectEvent(event)"
            v-bind:class="[checkIfSelected(event) ? 'selected-event' : '', 'event-item']"
          >
            <div class="event-info">
              <p class="date">{{ getFormattedDate(event) }}</p>
              <p class="separator"></p>
              <p class="time">{{ getDateTime(event) }}</p>
              <p class="pk">{{ calculatePk(event) }}</p>
              <!--             <img
              class="action-img"
              src="../../../public/assets/download.svg"
              @click="downloadEvent(event)"
            /> -->
              <img v-if="!disableEventDeletion" class="action-img" src="../../../public/assets/trash.svg" @click.stop="deleteEvent(event)" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import dates from "../../helpers/dates";
/* import api from "../../services/api";
 */
export default {
  name: "acc-list",
  props: {
    events: Array,
    useTimestamp: Boolean,
    disableEventDeletion:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortFlagByDate: 1,
      sortFlagByPk: 0,
      selectedEvent: null,
    };
  },
  methods: {
    selectEvent: function(event) {
      if (this.selectedEvent != event) {
        this.selectedEvent = event;
        this.$emit("event-selected", event._id);
      }
    },
    downloadEvent: async function(event) {
      this.$emit("event-download", event._id);
    },
    deleteEvent: async function(event) {
      this.$emit("event-delete", event._id);
    },
    getFormattedDate: function(event) {
      return dates.ddmmyyyyFormat(this.useTimestamp && event.timestamp ? event.timestamp : event.date);
    },
    checkIfSelected(event) {
      return this.selectedEvent && event._id == this.selectedEvent._id;
    },
    calculatePk(event) {
      let peak;
      if (event.acceleration) {
        peak = (Math.max(event.acceleration.xPeak, event.acceleration.yPeak, event.acceleration.zPeak) * 1000).toFixed(2);
      } else if (event.payload) {
        peak = (event.payload.piccoMax - event.payload.piccoMin).toFixed(2);
      }
      return peak;
    },
    getDateTime: function(event) {
      let date = new Date((this.useTimestamp && event.timestamp) ? event.timestamp : event.date);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds;
    },
    sortByDate: function() {
      this.sortFlagByPk = 0;
      this.sortFlagByDate = this.sortFlagByDate == -1 ? 1 : -1;
      this.selectedEvent = this.computedEvents[0];
      this.$emit("event-selected", this.computedEvents[0]._id);
    },
    sortByPk: function() {
      this.sortFlagByDate = 0;
      this.sortFlagByPk = this.sortFlagByPk == -1 ? 1 : -1;
      this.selectedEvent = this.computedEvents[0];
      this.$emit("event-selected", this.computedEvents[0]._id);
    },
  },
  computed: {
    computedEvents: function() {
      let tempBuffer = JSON.parse(JSON.stringify(this.events));
      if (this.sortFlagByPk == 1) {
        tempBuffer.sort((a, b) => this.calculatePk(b) - this.calculatePk(a));
      }
      if (this.sortFlagByPk == -1) {
        tempBuffer.sort((a, b) => this.calculatePk(a) - this.calculatePk(b));
      }
      if (this.sortFlagByDate == 1) {
        tempBuffer.sort((a, b) => b.date.localeCompare(a.date));
      }
      if (this.sortFlagByDate == -1) {
        tempBuffer.sort((a, b) => a.date.localeCompare(b.date));
      }
      return tempBuffer;
    },
  },
  watch: {
    events: function(newValue) {
      this.selectedEvent = newValue[0];
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 0.9em;
}
li {
  font-size: 1em;
}
.event-list {
  display: grid;
}
p {
  text-align: center;
  margin: 0;
  padding: 0;
}
.legend {
  background-color: rgb(80, 84, 105);
  display: grid;
  grid-template-columns: 5fr 25% 5fr 10%;
  cursor: default;
  border-radius: 10px;
  font-weight: 700;
  margin-top: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  position: absolute;
  width: 85%;
  margin-left: 7.5%;
  top: 100px;
  z-index: 99;
}
.legend > img {
  margin-top: auto;
  margin-bottom: auto;
}
.heading {
  margin: 22px 8%;
  text-align: left;
  position: absolute;
  top: 0;
}
.heading > p {
  text-align: left;
}
#events {
  position: absolute;
  top: 150px;
  border-radius: 10px;
  z-index: 1;
  height: 100%;
  padding-bottom: 160px;
}

.event-box {
  width: 100%;
  overflow: scroll;
}
.event-info {
  display: grid;
  font-weight: 600;
  grid-template-columns: 40% 25% 25% 10%;
  align-items: center;
}

.date {
  grid-column: 1;
  grid-row: 1;
}

.time {
  grid-column: 2;
  grid-row: 1;
  margin-left: 10%;
  text-align: left;
}

.pk {
  grid-column: 3;
  grid-row: 1;
}

.action-img {
  grid-column: 4;
  grid-row: 1;
  align-self: center;
  justify-self: center;
}

.buttons-wrapper {
  margin-top: 30px;
  display: grid;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}
.selected-event {
  background-color: rgb(21, 146, 230) !important;
}

* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li {
  cursor: pointer;
  margin-top: -1px; /* Prevent double borders */
  background-color: rgb(32, 34, 47);
  padding: 6px;
  text-decoration: none;
  color: black;
  display: block;
  position: relative;
  border-radius: 10px;
  color: white;
}

ul li:hover {
  background-color: rgb(47, 112, 177, 0.4);
}
.close {
  cursor: pointer;
  position: absolute;
  right: 0%;
  border-radius: 10px;
  background-color: grey;
}
.close:hover {
  background: #bbb;
}
.event-item {
  margin-bottom: 15px;
}
</style>
