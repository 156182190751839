import * as moment from 'moment'

/**
 * Calculates battery life duration and remaining percentage
 * @param {Object} payload - The object containing the list of data acquisition events and time interval
 * @param {Array} payload.eventList - The list of data acquisition events, each of which contains the acquired current and the acquisition duration
 * @param {Array} payload.dateRange - The time interval over which to calculate battery life duration
 * @returns {Object} An object containing the battery life duration calculated in months and the consumption percentage
*/
export function getEnergyConsumption(sensor, payload = { eventList: [], dateRange: [] }) {
    if (!sensor || !sensor.type) {
        return {}
    }
    switch (sensor.type) {
        case 'analog-node':
            return getEnergyConsumptionAnalogNodeV2(payload)
        default:
            return {}
    }
}


/**
* Calculates battery life duration and remaining percentage
* @param {Object} payload - The object containing the list of data acquisition events and time interval
* @param {Array} payload.eventList - The list of data acquisition events, each of which contains the acquired current and the acquisition duration
* @param {Array} payload.dateRange - The time interval over which to calculate battery life duration
* @returns {Object} An object containing the battery life duration calculated in months and the consumption percentage
*/
function getEnergyConsumptionAnalogNodeV2(payload = { eventList: [], dateRange: [] }) {
    const { accConsumo, totalAcqTime } = payload.eventList.reduce((acc, event) => {
        acc.accConsumo += event.current * event.samplingDuration;
        acc.totalAcqTime += event.samplingDuration;
        return acc;
    }, { accConsumo: 0, totalAcqTime: 0 });
    const startDate = moment(payload.dateRange[0])
    const endDate = moment(payload.dateRange[1]);
    const dateInterval = endDate.diff(startDate, 'seconds');
    const batteryDurationInMonths = (19000 * dateInterval / (720 * (accConsumo + 0.4 * (dateInterval - totalAcqTime)))).toPrecision(3)
    let batteryConsumption = 0;
    if (batteryDurationInMonths < 6) {
        batteryConsumption = 100;
    }
    else if (batteryDurationInMonths >= 6 && batteryDurationInMonths <= 24) {
        batteryConsumption = ((24 - batteryDurationInMonths) / 18) * 100
    } else {
        batteryConsumption = 0
    }

    return {
        batteryDurationInMonths,
        batteryConsumption
    }
}