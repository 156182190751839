<i18n>
{
  "en": {
      "title": "PCPV - Peak Component Particle Velocity",
      "subTitle": "PCPV is defined as the maximum value of the modulus of the velocity vector of each of the three orthogonal components measured simultaneously at a point."

  },
  "it": {
      "title": "PCPV - Peak Component Particle Velocity",
      "subTitle": "La PCPV è definita come il valore massimo del modulo del vettore velocità di ognuna delle tre componenti ortogonali misurate simultaneamente in un punto."
  }
}
</i18n>

<template>
  <BalloonTemplate arrowDirection="left" id="root" :visible="visible" @close="$emit('close')">
    <template v-slot:header>
      <h1>{{ $t("title") }}</h1>
    </template>
    <template v-slot:content>
      <p>{{ $t("subTitle") }}</p>
    </template>
  </BalloonTemplate>
</template>
<script>
import BalloonTemplate from "../../BallonTemplate.vue";

export default {
  name: "pcpvVibBalloon",
  components: {
    BalloonTemplate,
  },
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
#root {
  width: 560px;
}
</style>
