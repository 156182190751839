<template>
  <div>
    <EnergyConsumptionCard @click-details="modalEnargyConsumptionDetails = true"/>
    <EnergyConsumptionDetails v-if="modalEnargyConsumptionDetails" @click-close="modalEnargyConsumptionDetails = false" />
  </div>
</template>

<script>
import EnergyConsumptionCard from './EnergyConsumptionCard.vue';
import EnergyConsumptionDetails from './EnergyConsumptionDetails.vue';
export default {
  name: 'EnergyConsumption',
  components: {EnergyConsumptionCard, EnergyConsumptionDetails},
  data(){
    return {
      modalEnargyConsumptionDetails: false
    }
  }
 
};
</script>